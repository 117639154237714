import React, { useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import EditDeliveryCharge from "../../Components/EditDeliveryCharge";
import { ListGroup } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./index.css";
import { Radio, RadioGroup } from "@mui/material";
import { message } from "antd";

const distanceTable = {
  label: "Delivery Max-Distance",
  name: "deliveryMaxDistance",
  type: "selectbox",
  value: "0",
  options: ["0", "3", "6", "9", "12", "15"],
};

const holidayList = [
  {
    value: 0,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];
const AdditionalDetails = (props) => {
  React.useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  let { store } = props.searchDetails;
   console.log("store", store);
  const [distance, setDistance] = useState(store.deliveryMaxDistance);
  const [openingTime, setTime] = useState(
    store.openingTime ? new Date(`2014-08-18T${store.openingTime}`) : null
  );
  const [closingTime, setCTime] = useState(
    store.closingTime ? new Date(`2014-08-18T${store.closingTime}`) : null
  );

  //const dispatch = useDispatch();
  const [preparationTime, setpreparationTime] = useState(store.preparationTime);
  const [packingCharge, setpackingCharge] = useState(store.packingCharge);

  const handlePreparationTimeChange = (e) => {
    let newPreparationTime = e.target.value;
    setpreparationTime(newPreparationTime);
    props.changePreperationTime({
      e: {
        target: {
          name: "preparationTime",
          value: newPreparationTime,
          details: props.searchDetails.store,
        },
      },
      params: props.searchDetails.store,
    });
  };

  const handlePackingChargeChange = (e) => {
    let newPackingCharge = e.target.value;
    setpackingCharge(newPackingCharge);
    props.changePackingCharge({
      e: {
        target: {
          name: "packingCharge",
          value: newPackingCharge,
          details: props.searchDetails.store,
        },
      },
      params: props.searchDetails.store,
    });
  };

  const validationSchema = Yup.object().shape({
    minAmount: Yup.string()
      .trim("Space not allowed")
      .matches(
        /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,
        "Please enter only numbers"
      )
      .required("Please enter Minimum Amount"),
  });

  const formik = useFormik({
    initialValues: {
      minAmount: props.searchDetails.store.minAmount || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (openingTime.getTime() === closingTime.getTime()) {
        alert("Opening and closing time must be different");
      } else {
        props.saveDetails({
          ...props.searchDetails.store,
          minAmount: values.minAmount,
        });
        if (
          (props.searchDetails &&
            props.searchDetails.store &&
            ((props.searchDetails.store.deliveryType &&
              props.searchDetails.store.deliveryType.length === 0) ||
              (props.searchDetails.store.deliveryMaxDistance &&
                props.searchDetails.store.deliveryMaxDistance === "") ||
              (props.searchDetails.store.deliveryMaxDistance &&
                props.searchDetails.store.deliveryMaxDistance === null) ||
              (props.searchDetails.store.deliveryDays &&
                props.searchDetails.store.deliveryDays.length === 0) ||
              (props.searchDetails.store.deliveryCharge &&
                props.searchDetails.store.deliveryCharge.length === 0) ||
              (props.searchDetails.store.paymentSupported &&
                props.searchDetails.store.paymentSupported.length === 0) ||
              (props.searchDetails.store.openingTime &&
                props.searchDetails.store.openingTime === "") ||
              (props.searchDetails.store.openingTime &&
                props.searchDetails.store.openingTime === null) ||
              (props.searchDetails.store.closingTime &&
                props.searchDetails.store.closingTime === null) ||
              (props.searchDetails.store.closingTime &&
                props.searchDetails.store.openingTime === ""))) ||
          (props.searchDetails.store.preparationTime &&
            props.searchDetails.store.preparationTime === "") ||
          (props.searchDetails.store.preparationTime &&
            props.searchDetails.store.preparationTime === null)
        ) {
          alert("Please fill all the details !");
        } else {
          props.next();
        }
      }
    },
  });

  const getTimeChange = (e) => {
    setTime(e);
    props.textStoreChanges(
      {
        target: {
          name: "openingTime",
          value: `${e.toString().split("D")[0].substring(16, 21)}`,
        },
      },
      props.searchDetails.store
    );
  };
  const getCTimeChange = (e) => {
    setCTime(e);
    props.textStoreChanges(
      {
        target: {
          name: "closingTime",
          value: e.toString().split("D")[0].substring(16, 21),
        },
      },
      props.searchDetails.store
    );
  };
  // if (openingTime.getTime() === closingTime.getTime()) {
  //     message.error({
  //       content: "Opening and closing time must be different",
  //       className: "custom-class",
  //       style: {
  //         marginTop: "10vh",
  //         marginLeft: "15vw",
  //       },
  //       duration: 0.5,
  //     });
  // }
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const [click] = React.useState(true);

  return (
    <Container>
      <div className="editform_div">
        <h4
          style={{
            paddingTop: "30px",
            fontWeight: 500,
            textAlign: "center",
            color: "black",
            textDecoration: "underline",
          }}
        >
          {"Additional Details"}
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid item xs={12}>
              <FormLabel component="legend" style={{ textAlign: "left" }}>
                Delivery Type
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  key={0}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.checkBoxEdit(e, store);
                      }}
                      name="deliveryType"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value="delivery"
                      defaultChecked={
                        store && store.deliveryType?.includes("delivery")
                          ? true
                          : false
                      }
                    />
                  }
                  label="Delivery"
                />
                <FormControlLabel
                  key={1}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.checkBoxEdit(e, props.searchDetails.store);
                      }}
                      name="deliveryType"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value="pick-up"
                      defaultChecked={
                        store && store.deliveryType?.includes("pick-up")
                          ? true
                          : false
                      }
                    />
                  }
                  label="PickUp"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Row className="grid_padd">
                <Col xs={6} className="grid_padd">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormLabel component="legend">Opening Time</FormLabel>
                    <TimePicker
                      name={"openingTime"}
                      value={openingTime}
                      onChange={(e) => {
                        getTimeChange(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={onKeyDown}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
                <Col xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormLabel component="legend">Closing Time</FormLabel>
                    <TimePicker
                      name={"closingTime"}
                      value={closingTime}
                      onChange={(e) => {
                        getCTimeChange(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={onKeyDown}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left", marginTop: "20px" }}>
              <Row className="grid_padd">
                <Col xs={6} className="grid_padd">
                  <TextField
                    label="Preparation Time"
                    value={preparationTime}
                    onChange={handlePreparationTimeChange}
                    fullWidth
                  />
                </Col>

                <Col xs={6}>
                  <TextField
                    label="Packing Charge"
                    value={packingCharge}
                    onChange={handlePackingChargeChange}
                    fullWidth
                  />
                </Col>
              </Row>
            </Grid>
            {/* ____________HOLIDAYS______________ */}
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", paddingTop: "20px" }}
            >
              <FormLabel component="legend">Holidays</FormLabel>
              <FormGroup row>
                {holidayList?.map((day, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          props.checkBoxEdit(e, props.searchDetails.store);
                        }}
                        name="holidays"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        value={day.value}
                        defaultChecked={
                          store &&
                          store.holidays &&
                          store.holidays?.includes(day.value)
                            ? true
                            : false
                        }
                      />
                    }
                    label={day.label}
                  />
                ))}
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ color: "black", textAlign: "left", paddingTop: "20px" }}
            >
              <FormLabel component="legend">Payment Supported</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.checkBoxEdit(e, props.searchDetails.store);
                      }}
                      name="paymentSupported"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value="Cash"
                      defaultChecked={
                        store && store.paymentSupported?.includes("Cash")
                          ? true
                          : false
                      }
                    />
                  }
                  label="Cash"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.checkBoxEdit(e, props.searchDetails.store);
                      }}
                      name="paymentSupported"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value="Card"
                      defaultChecked={
                        store && store.paymentSupported?.includes("Card")
                          ? true
                          : false
                      }
                    />
                  }
                  label="Card"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        props.checkBoxEdit(e, props.searchDetails.store);
                      }}
                      name="paymentSupported"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value="Online"
                      defaultChecked={
                        store && store.paymentSupported?.includes("Online")
                          ? true
                          : false
                      }
                    />
                  }
                  label="Online"
                />
              </FormGroup>
            </Grid>
            <br />
            <br />

            <Grid
              item
              xs={12}
              style={{ color: "black", textAlign: "left", paddingTop: "20px" }}
            >
              <FormLabel component="legend">
                Truncate Shop Assistant flow
              </FormLabel>
              <FormGroup row>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={
                    store &&
                    store.shopAsstTruncatedFlow &&
                    store.shopAsstTruncatedFlow === true
                      ? "yes"
                      : "no"
                  }
                >
                  <FormControlLabel
                    value={"yes"}
                    control={
                      <Radio
                        defaultChecked={
                          store &&
                          store.shopAsstTruncatedFlow &&
                          store.shopAsstTruncatedFlow === true
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          props.handleShopAssistant(
                            true,
                            props.searchDetails.store
                          );
                        }}
                        name="truncate"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={"no"}
                    control={
                      <Radio
                        name="notruncate"
                        defaultChecked={
                          store &&
                          store.shopAsstTruncatedFlow &&
                          store.shopAsstTruncatedFlow === false
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          props.handleShopAssistant(
                            false,
                            props.searchDetails.store
                          );
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormGroup>
            </Grid>
            <br />
            <br />

            <Grid
              item
              xs={12}
              style={{ textAlign: "left", paddingTop: "20px" }}
            >
              <FormLabel>Choose your normal Delivery Schedule below</FormLabel>
            </Grid>
            <br />
            <br />
            <Grid item xs={12} md={12} style={{ width: "100%" }}>
              <ListGroup style={{ marginBottom: 12, width: "100%" }} horizontal>
                <ListGroup.Item className={"day"}>Day</ListGroup.Item>
                <ListGroup.Item key={1} className={"slot"}>
                  Slot 1
                </ListGroup.Item>
                <ListGroup.Item key={2} className={"slot"}>
                  Slot 2
                </ListGroup.Item>
                <ListGroup.Item key={3} className={"slot"}>
                  Slot 3
                </ListGroup.Item>
                <ListGroup.Item key={4} className={"slot"}>
                  Slot 4
                </ListGroup.Item>
              </ListGroup>

              {store &&
                store.deliveryDays?.map((day, key) => {
                  // Check if all the slots are "No"
                  const allSlotsNo =
                    day.T9amTO12pm === "No" &&
                    day.T12pmTO3pm === "No" &&
                    day.T3pmTO6pm === "No" &&
                    day.T6pmTO9pm === "No";

                  // Only display the day if not all slots are "No"
                  if (!allSlotsNo) {
                    return (
                      <React.Fragment key={key}>
                        <ListGroup horizontal className="list">
                          <ListGroup.Item className={"days"}>
                            {day.deliveryDay}
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="list"
                            active={day.T9amTO12pm === "Yes"}
                            onClick={() => {
                              props.scheduleEdit({
                                key,
                                timing: "T9amTO12pm",
                                scheduleDetail: props.searchDetails.store,
                              });
                            }}
                            style={{
                              width: 200,
                              height: 30,
                              textAlign: "center",
                              borderLeftWidth: 1,
                              margin: "0 15px",
                              padding: 5,
                            }}
                          >
                            9AM-12PM
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="list"
                            active={day.T12pmTO3pm === "Yes"}
                            onClick={() => {
                              props.scheduleEdit({
                                key,
                                timing: "T12pmTO3pm",
                                scheduleDetail: props.searchDetails.store,
                              });
                            }}
                            style={{
                              width: 200,
                              height: 30,
                              textAlign: "center",
                              borderLeftWidth: 1,
                              margin: "0 15px",
                              padding: 5,
                            }}
                          >
                            12PM-3PM
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="list"
                            active={day.T3pmTO6pm === "Yes"}
                            onClick={() => {
                              props.scheduleEdit({
                                key,
                                timing: "T3pmTO6pm",
                                scheduleDetail: props.searchDetails.store,
                              });
                            }}
                            style={{
                              width: 200,
                              height: 30,
                              textAlign: "center",
                              borderLeftWidth: 1,
                              margin: "0 15px",
                              padding: 5,
                            }}
                          >
                            3PM-6PM
                          </ListGroup.Item>
                          <ListGroup.Item
                            className="list"
                            active={day.T6pmTO9pm === "Yes"}
                            onClick={() => {
                              props.scheduleEdit({
                                key,
                                timing: "T6pmTO9pm",
                                scheduleDetail: props.searchDetails.store,
                              });
                            }}
                            style={{
                              width: 200,
                              height: 30,
                              textAlign: "center",
                              borderLeftWidth: 1,
                              margin: "0 15px",
                              padding: 5,
                            }}
                          >
                            6PM-9PM
                          </ListGroup.Item>
                        </ListGroup>
                        <hr
                          style={{
                            height: 0.5,
                            backgroundColor: "#75757554",
                          }}
                        />
                      </React.Fragment>
                    );
                  }

                  return null; // Don't render anything if all slots are "No"
                })}
            </Grid>
            <br />
            <br />
            <Grid>
              <Grid item xs={12}>
                <TextField
                  InputProps={{ style: { height: 50, borderRadius: "15px" } }}
                  id="filled-read-only-input"
                  label={"Minimum Order Amount"}
                  background="red"
                  name={"minAmount"}
                  value={formik.values.minAmount}
                  onKeyPress={(event) => {
                    if (event?.key === "-" || event?.key === "+") {
                      event.preventDefault();
                    }
                  }}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.minAmount && Boolean(formik.errors.minAmount)
                  }
                  helperText={
                    formik.touched.minAmount && formik.errors.minAmount
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid item xs={12}>
                <FormLabel
                  component="legend"
                  style={{
                    textAlign: "left",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  Maximum Distance Delivery
                </FormLabel>

                <NativeSelect
                  input={<OutlinedInput label="Maximum Distance Delivery" />}
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  value={distance}
                  name="deliveryMaxDistance"
                  onChange={(e) => {
                    setDistance(e.target.value);
                    props.changeMaxDistance({
                      value: e.target.value,
                      details: store,
                    });
                  }}
                  inputProps={{ "aria-label": "age" }}
                >
                  <option value="" disabled>
                    in Kms
                  </option>
                  {distanceTable.options?.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Grid>
              <br />
              <br />
              {click === true ? (
                <Grid item xs={12}>
                  <EditDeliveryCharge {...props} distance={distance} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <div className="buttons" style={{ paddingTop: "15px" }}>
            <button type="button" onClick={props.back} className="nextbutton">
              Back
            </button>
            <button type="submit" className="nextbutton">
              {"Save & Next"}
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default AdditionalDetails;
