import { React, useEffect, useState } from "react";
import CustomDialog from "../CommonComponents/dialog";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontSize_18,
  contentFontSize_20,
  HeaderFontSize_25,
  HeaderFontSize_24,
  contentFontSize_22,
  contentFontsize_28,
} from "../Typography/index";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  InputAdornment,
  Select,
  DialogContent,
  //makeStyles,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./PendingView";
import { useLocation } from "react-router-dom";
import Amount from "../utils/Icon/Amount.svg";
import deliver from "../utils/Icon/scooter.svg";
import payment from "../utils/Icon/paymentmethod.svg";
import edit from "../utils/Icon/Edit1.svg";
import save from "../utils/Icon/Save.svg";
import CustomCard from "../CommonComponents/card";
import CustomButton from "../CommonComponents/button";

function TransferPopup({ transfer, setTransfer }) {
  const handleClose = () => {
    setTransfer(false);
  };
  useEffect(() => {
    console.log("page open");
    console.log("transfer", transfer);
  });
  return (
    <Dialog
      width="100%"
      height="600px"
      borderRadius="20px"
      open={transfer}
      onClose={handleClose}
      showCloseButton={true}
      right="20px"
      top="20px"
      bottom="20px"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px",
            // Set your width here
            padding: "10px 10px 30px 10px",
            borderRadius: "20px",
          },
        },
      }}
    >
      <DialogTitle>
        <Typography
          fontSize={contentFontsize_28}
          sx={{
            fontFamily: "Roboto",
            fontWeight: "700",
            display: "flex",
            // justifyContent: "center",
            color: "black",
            marginTop: "20px",
          }}
        >
          Transfer
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <div
            style={{
              height: "27px",
              width: "27px",
              border: "2px solid #8B8989",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon style={{ color: "#8B8989" }} />
          </div>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "0px 24px" }}>
        <form>
          <div>
            <Typography
              style={{
                textAlign: "justify",
                fontFamily: "Roboto",
                fontWeight: "400",
              }}
              fontSize={contentFontSize_18}
            >
              autopay by razor pay/03245676543 autopay by razor pay/03245676543
              autopay by razor pay/03245676543
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          ></div>

          <div style={{ marginTop: "20px" }}>
            <Typography sx={{ color: "black" }}>
              Choose Linked Account
            </Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Select
              className="custom-select_1"
              variant="outlined"
              style={{ width: "100%" }}
              SelectProps={{ style: { width: "100%", height: 35 } }}
              InputLabelProps={{ shrink: true }}
              name="status"
            ></Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
          >
            <CustomButton width="150px">
              <Typography
                style={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                }}
                fontSize={ButtonFontSize_18}
              >
                Add Account
              </Typography>
            </CustomButton>
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div>
              <CustomButton
                color="black"
                background="transparent"
                height="40px"
                borderRadius="20px"
                borderColor="rgb(137, 199, 74, 1)"
                width="280px"
                // onClick={handleCancel}
              >
                Cancel
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color="rgb(255, 255, 255)"
                backgroundColor="rgb(137, 199, 74, 1)"
                height="40px"
                borderRadius="20px"
                width="280px"
                type="submit"
              >
                Save
              </CustomButton>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default TransferPopup;
