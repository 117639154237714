import React, { useEffect, useRef, useState } from "react";
import "./pageStyle.css";
import {
  Breadcrumbs,
  DialogContent,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import Direction from "../Utilities/Icon/Direction.svg";
import DirectionSub from "../Utilities/Icon/DirectionSub.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import ReadyForLine from "../Utilities/Icon/ReadyForLine.svg";
import { Navigate, useLocation } from "react-router-dom";
import lines from "../Utilities/Icon/lines.svg";
import UploadImage from "../Utilities/Icon/UploadImage.svg";
import Camera from "../Utilities/Icon/Camera.svg";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
import BlueClock from "../Utilities/Icon/BlueClock.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import CustomButton from "../CommonComponents/button";
import OutForDelivery from "./OutForDelivery";
import deliver from "../utils/Icon/scooter.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_17,
  HeaderFontSize_24,
} from "../Typography";
// import NewImg from "../../AddProduct/ImageCropper/NewImg"
import CustomCard from "../CommonComponents/card";
import { Upload, message } from "antd";
import OrderImage from "../AddProducts/ImageCropper/OrderImage";
import LoaderReady from "./Loader/LoaderReady";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GoogleMapOrder from "./GoogleMapOrder";
import MultiImgOrder from "../AddProducts/ImageCropper/MultiImgOrder";
import CustomDialog from "../CommonComponents/dialog";
import CustomTextField from "../CommonComponents/input/index";
// import EditGoogleMap from "../../EditGoogleMap";
function ReadyOrder(props) {
  // const location = useLocation();
  const orderid = localStorage.getItem("orderID");
  console.log("orderId:", orderid);
  const [direction, setDirection] = useState(false);
  // useEffect(() => {
  //   props.getOrderDetails(orderid);
  //   console.log("two", props.getOrderDetails(orderid));
  // }, []);

  const readyOrderDetails =
    props?.OrderDetails && props?.OrderDetails?.orderdetails;
  console.log("readyOrderDetails", readyOrderDetails);

  const [data, setData] = useState([
    {
      orderNumber: "1025",
      deliveryDate: "Thu Sep 19 2023",
      deliveryTime: "10:00-12:00pm",
      products: [{ name: "apple", quantity: 2, price: "120" }],
    },
  ]);

  // const nav = useNavigate();
  function handleClickOne() {
    // nav("/home/MyOrders/ReadyForDelivery");
  }
  const Datas = JSON.parse(localStorage.getItem("selectedItems")) || [];
  const DataOut = JSON.parse(localStorage.getItem("orderCardDetails")) || [];

  // const totalProductPrice = data
  //   ? data.products.reduce((total, item) => total + item.quantity * item.price, 0)
  //   : 0;
  // Create a map from detailsReady for quick lookup
  const detailsReady =
    ("props",
    props &&
      props.ChangetoReady &&
      props.ChangetoReady.changeOrderStatusToReady);
  const totalProductPrice = detailsReady
    ? detailsReady.products.reduce(
        (total, item) => total + item.shopAssistantQuantity * item.productPrice,
        0
      )
    : readyOrderDetails
    ? readyOrderDetails.products.reduce(
        (total, item) => total + item.shopAssistantQuantity * item.productPrice,
        0
      )
    : 0;

  console.log(totalProductPrice); // Output the total price based on the given quantities and prices

  const deliveryCharge = readyOrderDetails?.deliveryCharge;
  const packingCharge =
    readyOrderDetails?.packingCharge + readyOrderDetails?.handlingCharge;
  const discountPrice = readyOrderDetails?.discountPrice;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;

  const OutbuttonStateRef = useRef(false);
  // const [OutForDelivery, setOutForDelivery] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [packingImages, setPackingimages] = useState("");
  const [showOutForDelivery, setShowOutForDelivery] = useState(false);
  const [readytoout, setReadyToOut] = useState("");
  //const [packingImages, setPackingimages] = useState("")
  const [packingImages, setPackingImages] = useState([]);
  //const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    const images = props?.multiImage?.uploadMultipleFiles.map((i) => i.Location);
    setPackingImages(images);
   
    // Store the images in localStorage
    localStorage.setItem('packingImages',  JSON.stringify(images));
    //setPackingImages([])
  }, [props?.multiImage?.uploadMultipleFiles]);
 
  // localStorage.setItem("images", JSON.stringify(packingImages));
console.log("packinImages",packingImages)
  useEffect(() => {
    setPackingImages([]);
  }, []);
  function handleOutForDelivery() {
    //console.log("image",JSON.parse?.localStorage?.getItem('images'));

    if (packingImages === undefined || packingImages === null) {
      message.error({
        content: "Please upload the image",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
    } else {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        if (packingImages) {
          props.changeToOutForDelivery(orderid, packingImages);
          //nav("/home/MyOrders/OutForDelivery");
          setShowOutForDelivery(true);
          // Clear the packingImages variable in state
          //setPackingImages(null);
        }
      }, 2000);
    }
  }

  const valueLabelMap = {
    1: "Seller",
    2: "NearShopz",
    3: "External Delivery Person",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(readyOrderDetails.deliveryBy); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setSelectedValue(value); 
    setAnchorEl(null);
    props.updateDeliveryBy({
      orderid: readyOrderDetails.id,
      deliveryBy: value,
    });
    console.log("Selected:", value, "Order ID:", readyOrderDetails.id);
  };

  // Log the packingImages array whenever it changes

  //const history = useHistory()
  // const directionRef = useRef(false);

  // useEffect(() => {
  //   console.log("direction changed:", directionRef.current);
  // }, [directionRef.current]);

  // function handleDirection() {
  //   console.log("enter");
  //   directionRef.current = true;
  //   console.log("new direction:", directionRef.current);
  // }

  useEffect(() => {
    setPackingImages(null);
  }, []);
  console.log("poda", detailsReady);
  function handleClickTwo() {
    // nav("/home/MyOrders");
  }
  //   useEffect(()=>{
  //     props.changeToOutForDelivery(orderid,packingImages)
  //   })
  //  const readyOrders = props.ChangetoReadyStatus

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  console.log("pro", props?.proImage?.imageEditAndUpload?.location);
  const [share, setShare] = useState(false);
  const [open, setOpen] = useState(false);
  function handleShare() {
    setShare(true);
    setOpen(true);
  }
  const url = localStorage.getItem("googleMapsUrl");

  const [copySuccess, setCopySuccess] = useState(false);

  console.log("the dynamic link", url);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();

  const destination = {
    lat: Number(readyOrderDetails?.deliveryLat),
    lng: Number(readyOrderDetails?.deliveryLng),
  };
  const origin = {
    lat:
      Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1]) ||
      10.850516,
    lng:
      Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0]) ||
      76.27108,
  };
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`;
  localStorage.setItem("googleMapsUrl", googleMapsUrl);

  return (
    <div>
      {!loading && !showOutForDelivery && !direction && (
        <>
          <div style={{ display: "flex", gap: "50px" }}>
            <div style={{ width: "55%" }}>
              <div style={{ marginTop: "30px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img
                          src={Direction}
                          style={{ width: "20px", height: "20px" }}
                          alt="Direction Icon"
                        />

                        <div>
                          <p className="nameStyle1" style={{ color: "black" }}>
                            {" "}
                            {readyOrderDetails &&
                              readyOrderDetails.customerName}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          {readyOrderDetails &&
                            readyOrderDetails?.deliveryAddress}
                          ,
                          {readyOrderDetails && readyOrderDetails?.deliveryDate}
                          ,
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {readyOrderDetails && readyOrderDetails.deliverytime}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        height: "100%",
                        marginTop: "18px",
                      }}
                    >
                      <img
                        src={Phone}
                        style={{ width: "20px", height: "20px" }}
                        alt="Phone icon"
                      />
                      <span>
                        <p className="numberStyle1" style={{ color: "black" }}>
                          +91 {readyOrderDetails?.userid?.phoneNumber}
                        </p>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div>
                  <img style={{ width: "100%" }} src={ReadyForLine} />
                </div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ marginTop: "25px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "80px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </div>
                          <div className="connecting-line"></div>
                          {/* </div> */}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "1",
                          }}
                        >
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            Outlet
                          </div>
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {/* 35 mins */}
                            {readyOrderDetails?.travelTime?.text}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "1",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            {readyOrderDetails?.storeid?.storeName}
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            {/* 3.6 kilometers */}
                            {readyOrderDetails?.travelDistance?.text}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    //sx={{ margin: "1px -20px 44px 47px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        height: "100%",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        alignItems: "center",
                        marginTop: smmatch ? "20px" : "0px",
                      }}
                    >
                      <div>
                        <CustomButton
                          width="120px"
                          height="45px"
                          variant="outlined"
                          color="#85BE49"
                          borderColor="#85BE49"
                          background="none"
                          hasIcon={true}
                          iconPosition="startIcon"
                          onClick={() => setDirection(true)}
                        >
                          <img
                            src={Direction}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                          Direction
                        </CustomButton>
                      </div>

                      <div onClick={handleShare}>
                        <img
                          onClick={handleShare}
                          src={DirectionSub}
                          style={{
                            width: "25px",
                            height: "33px",
                            marginRight: "24px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {share && (
                  <CustomDialog
                    width="600px"
                    height="200px"
                    borderRadius="20px"
                    open={open}
                    onClose={handleClose}
                    titleAlign="center"
                    // title="Share URL"
                    top={"15px"}
                    right={"20px"}
                    // showCloseButton={false}
                  >
                    <Typography
                      style={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                      className="head-font"
                      fontSize={HeaderFontSize_24}
                    >
                      Share URL
                    </Typography>
                    <DialogContent>
                      <div>
                        <CustomTextField
                          width="100%"
                          value={url}
                          inputRef={inputRef}
                          endAdornment={
                            <InputAdornment position="end">
                              <div>
                                <CustomButton
                                  onClick={() => copyCodeToClipboard()}
                                >
                                  <Typography fontSize={contentFontSize_16}>
                                    Copy Url
                                  </Typography>
                                </CustomButton>
                              </div>
                            </InputAdornment>
                          }
                        ></CustomTextField>
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {copySuccess ? (
                            <div
                              style={{ color: "#85BE49", paddingTop: "6px" }}
                            >
                              <Typography fontSize={contentFontSize_16}>
                                Copied to Clipboard
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </DialogContent>
                  </CustomDialog>
                )}
              </div>

              <div
                className="readyfordeliveryBox"
                // style={{ marginLeft: matches ? "0px" : "50px" }}
              >
                <span>
                  <p
                    fontSize={contentFontSize_18}
                    style={{ marginBottom: "0px", color: "black" }}
                    className="textStyleReady"
                  >
                    #{readyOrderDetails && readyOrderDetails.orderNumber} -
                    Scheduled on{" "}
                    {readyOrderDetails && readyOrderDetails.deliveryDate},{" "}
                    {readyOrderDetails && readyOrderDetails.deliveryTime}
                  </p>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "20px",
                  padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
                }}
              >
                {(detailsReady
                  ? detailsReady.products
                  : readyOrderDetails.products
                )?.map((item) => (
                  <div style={{ display: "flex" }} key={item.id}>
                    {console.log("item received", item)}
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        <div className="productDetailsOut">
                          <img
                            style={{
                              width: matchessm ? "15px" : "20px",
                              height: matchessm ? "15px" : "20px",
                            }}
                            src={Pointer}
                            alt={item?.name}
                          />
                        </div>

                        <div>
                          <p
                            fontSize={contentFontsize_17}
                            className="productDetailsOut"
                            style={{ textAlign: "left", color: "black" }}
                          >
                            {item?.productid?.productname}
                            <br />
                            <p
                              fontSize={contentFontsize_14}
                              style={{
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {item.shopAssistantQuantity}X ₹
                              {item?.productPrice?.toFixed(2)}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                          }}
                        >
                          <p
                            className="productDetailsOut"
                            fontSize={contentFontsize_17}
                            style={{ color: "black" }}
                          >
                            ₹{" "}
                            {(
                              item?.shopAssistantQuantity * item?.productPrice
                            )?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sm={12}
                  sx={{ marginTop: "20px" }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>

              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Total Product price
                  </p>
                  {/* </Grid> */}
                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                  <p className="productDetails1" fontSize={contentFontSize_16}>
                    ₹ {totalProductPrice?.toFixed(2)}
                  </p>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Delivery Charge
                  </p>

                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹ {readyOrderDetails?.deliveryCharge?.toFixed(2)}
                  </p>
                </div>

                {readyOrderDetails?.discountPrice ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      fontSize={contentFontSize_16}
                      style={{ color: "rgba(45, 147, 251, 1)" }}
                      className="productDetails1"
                    >
                      Coupon - ({readyOrderDetails?.couponID?.couponCode})
                    </p>
                    {/* </Grid> */}

                    {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                    <p
                      className="productDetails1"
                      style={{ color: "rgba(45, 147, 251, 1)" }}
                      fontSize={contentFontSize_16}
                    >
                      -₹ {readyOrderDetails?.discountPrice?.toFixed(2)}
                    </p>
                  </div>
                ) : null}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Packing Charges
                  </p>

                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"22px"}}
                  >
                    ₹ {packingCharge?.toFixed(2)}
                  </p>
                </div>
              </div>

              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>
              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    fontSize={contentFontSize_20_xs_18}
                    sx={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                    }}
                  >
                    Total
                  </Typography>
                  {/* </Grid>

                                   <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    ₹ {grandTotal.toFixed(2)}
                  </Typography>
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    color: "black",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Upload Product Images
                  <span style={{ color: "#EA4335" }}>*</span>
                </Typography>
              </div>
              <div style={{ marginTop: "30px", textAlign: "left" }}>
                <MultiImgOrder {...props} />
              </div>
              {readyOrderDetails.paymentStatus === 0 ? (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: smmatch ? "0px" : "0px",
                    textAlign: "left",
                  }}
                >
                  <p style={{ color: "#1D89E1" }}>
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "20px",
                      }}
                      src={BlueClock}
                    />
                    Waiting for Payment
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: smmatch ? "0px" : "0px",
                    textAlign: "left",
                  }}
                >
                  <p style={{ color: "#1D89E1" }}>
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "20px",
                      }}
                      src={BlueClock}
                    />
                    Payment Completed
                  </p>
                </div>
              )}
            </div>
            <div style={{ marginTop: "5%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img src={deliver} alt="Amount" />
                  <Typography
                    fontSize={contentFontsize_14}
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Roboto",
                      color: "#13801C",
                    }}
                  >
                    Delivered By
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Typography fontSize={16} style={{ fontWeight: "400" }}>
                    {valueLabelMap[selectedValue] || "Seller"}
                  </Typography>
                  <IconButton
                    onClick={handleClick}
                    disableRipple
                    disableFocusRipple
                    sx={{
                      padding: 0,
                      "&:focus": {
                        outline: "none",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose1}
                  >
                    <MenuItem onClick={() => handleMenuItemClick(1)}>
                      Seller
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick(2)}>
                      NearShopz
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick(3)}>
                      External Delivery Person
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div style={{ marginTop: "180px" }}>
                <div className="timeline-containerReady">
                  {(detailsReady
                    ? detailsReady.stats
                    : readyOrderDetails.stats
                  ).map((each, index) => (
                    <div key={index} className="timeline-entryOut">
                      <div className="timeline-markerOut">
                        <div className="dotActive"></div>
                        {index <
                          (detailsReady
                            ? detailsReady.stats.length
                            : readyOrderDetails.stats.length) -
                            1 && <div className="connecting-lineOut"></div>}
                      </div>
                      <div className="timeline-contentOut">
                        <div>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {each.status}
                          </p>
                          {each?.status === "Order-Placed" && (
                            <p
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              Scheduled for {readyOrderDetails.deliveryDate},{" "}
                              {readyOrderDetails.deliveryTime}
                            </p>
                          )}
                        </div>
                        <div className="detailsOut">
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              color: "#000000",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            {each?.created}, {each?.createdTime}
                            {/* Scheduled for {readyOrderDetails.deliveryDate},{" "}
                            {readyOrderDetails.deliveryTime} */}
                          </p>
                        </div>
                        {each.status === "Order-Accepted" &&
                          detailsReady?.shopAssistantId?.firstName && (
                            <div
                              className="shop-assistant"
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              Shop Assistant:{" "}
                              {detailsReady?.shopAssistantId?.firstName}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CustomButton
              width="350px"
              height="50px"
              borderRadius="20px"
              onClick={handleOutForDelivery}
            >
              <Typography
                fontSize={contentFontSize_18}
                className="deliveryButton"
                sx={{ textTransform: "capitalize" }}
              >
                {" "}
                Out For Delivery
              </Typography>
            </CustomButton>
          </div>
        </>
      )}

      {loading && <LoaderReady />}
      {showOutForDelivery && <OutForDelivery {...props} />}
      {direction && <GoogleMapOrder {...props} />}
    </div>
  );
}

export default ReadyOrder;
