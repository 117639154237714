import React, { useState, useEffect } from "react";
import {
  AdapterDayjs,
  DateTimePicker,
  //   DemoContainer,
  Grid,
  InputAdornment,
  LocalizationProvider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_22,
} from "../Typography";
import CustomBadge from "../CommonComponents/badge/index";
//import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Dialog } from "../MUIComponents/index";
import { DialogContent } from "../MUIComponents/index";
import closeIconPayment from "../utils/images/closeIconPayment.png";
import PaymentIconImage from "../utils/images/PaymentIconImage.png";
import copyPayment from "../utils/images/copyPayment.png";
import dayjs from "dayjs";
import calander_new from "../utils/images/calander_new.png";
import searchcopy from "../utils/Icon/searchcopy.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomButton from "../CommonComponents/button";
import Download from "../utils/Icon/download.svg";

function createData(
  no,
  name,
  calories,
  fat,
  carbs,
  delivery,
  platform,
  nearshopz,
  date,
  gstdelivery,
  gstmarkup,
  gstplatform,
  coupon,
  couponburn,
  markup,
  productprice,
  sellerfee,
  deliveryperson
) {
  return {
    no,
    name,
    calories,
    fat,
    carbs,
    delivery,
    platform,
    nearshopz,
    date,
    gstdelivery,
    gstmarkup,
    gstplatform,
    coupon,
    couponburn,
    markup,
    productprice,
    sellerfee,
    deliveryperson,
  };
}

// const rows = [
//   createData(
//     "01",
//     "#123",
//     "159",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
//   createData(
//     "02",
//     "#123",
//     "237",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
//   createData(
//     "03",
//     "#123",
//     "262",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
//   createData(
//     "04",
//     "#123",
//     "305",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
//   createData(
//     "05",
//     "#123",
//     "356",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
//   createData(
//     "06",
//     "#123",
//     "234",
//     "350.00",
//     "1234",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00",
//     "0.00"
//   ),
// ];

const headerStyle = {
  // width:"60px",
  backgroundColor: "rgba(84, 93, 95, 0.15)",
  color: "black",
  textAlign: "center",
  border: "1px solid white",
};

const cellStyle = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
};

const cellStyle2 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
};

const cellStyle4 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  color: "black",
  textDecoration: "underline",
};

const cellStyle1 = {
  minWidth: "200px",
  textAlign: "center",
  borderTop: "1px solid rgba(84, 93, 95, 0.15)",
  borderRight: "1px solid rgba(84, 93, 95, 0.15)",
  borderBottom: "1px solid rgba(84, 93, 95, 0.15)",
  borderLeft: "1px solid rgba(84, 93, 95, 0.15)",
  color: "black",
  textDecoration: "underline",
  cursor: "pointer",
};
const containerStyle = {
  width: "100%",
  overflowX: "auto",
  maxWidth: "1200px", // Adjust based on new column width
  scrollbarWidth: "none", // Hide scrollbar for Firefox
};

const noScrollbarStyle = {
  "&::-webkit-scrollbar": {
    display: "none", // Hide scrollbar for WebKit browsers
  },
  msOverflowStyle: "none", // Hide scrollbar for IE and Edge
};

function Reports(props) {
  const [newState, setNewState] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  const [first, setFirst] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    props.getOrderResult({ offset: offset, first: first });
  }, []);

  useEffect(() => {
    if (props?.searchOrderData) {
      const orders = props?.searchOrderData?.items;

      setRows(orders);
      console.log("njj", orders);
    }
  }, [props.searchOrderData]);

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  // const handlePendingClick = () => {
  //   nav("/home/Payments/PendingDeposits");
  // };

  // const handleSettlementsClick = () => {
  //   nav("/home/Payments");
  // };
  function MuiIcon() {
    return (
      <img
        src={calander_new}
        alt="Calendar icon"
        width="22px"
        height="21.99px"
      />
    );
  }
  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        height: "auto",
        padding: "15px",
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
      }}
      position="sticky"
    >
      <div style={{ textAlign: "left", textTransform: "uppercase" }}>
        <Typography
          style={{ fontFamily: "Roboto", fontWeight: "600" }}
          fontSize={HeaderNavigationFontSize_20}
        >
          Reports
        </Typography>
      </div>
      <div>
        <div style={{ width: "100%", margin: "40px 0px 30px 0px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div style={{ flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                <DatePicker
                  //   value={formik.values.dateFrom}
                  //   onChange={(newValue) =>
                  //     formik.setFieldValue("dateFrom", newValue)
                  //   }
                  renderInput={(props) => <TextField {...props} />}
                  //   error={
                  //     formik.touched.dateFrom &&
                  //     Boolean(formik.errors.dateFrom)
                  //   }
                  //   helperText={
                  //     formik.touched.dateFrom && formik.errors.dateFrom
                  //   }
                  slots={{ openPickerIcon: MuiIcon }}
                  slotProps={{
                    textField: {
                      placeholder: "From Date",
                      size: matches && "small",
                    },
                    openPickerIcon: { sx: { fontSize: "50px" } },
                    action: {
                      sx: { marginRight: "-8px", padding: "4px" },
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#D1D5DB" },
                      "&.Mui-focused fieldset": {
                        borderColor: "#D1D5DB",
                      },
                      borderRadius: "20px",
                      background: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "transparent",
                      borderRadius: "30px",
                      fontSize: matches ? "13px" : "16px",
                    },
                    "& .MuiIconButton-root": { marginRight: "5px" },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>
            </div>
            <div style={{ flex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                <DatePicker
                  //   value={formik.values.dateTo}
                  //   onChange={(newValue) =>
                  //     formik.setFieldValue("dateTo", newValue)
                  //   }
                  renderInput={(props) => <TextField {...props} />}
                  //   error={
                  //     formik.touched.dateTo && Boolean(formik.errors.dateTo)
                  //   }
                  //   helperText={formik.touched.dateTo && formik.errors.dateTo}
                  slots={{ openPickerIcon: MuiIcon }}
                  slotProps={{
                    textField: {
                      placeholder: "To Date",
                      size: matches && "small",
                    },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#D1D5DB" },
                      "&.Mui-focused fieldset": {
                        borderColor: "#D1D5DB",
                      },
                      borderRadius: "20px",
                      background: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "transparent",
                      borderRadius: "30px",
                      fontSize: matches ? "13px" : "16px",
                    },
                    "& .MuiIconButton-root": { marginRight: "5px" },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>
            </div>
            <div>
              <CustomButton
                color="rgb(255, 255, 255)"
                backgroundColor="rgb(137, 199, 74, 1)"
                height="50px"
                borderRadius="20px"
                width="280px"
                type="submit"
              >
                Apply
              </CustomButton>
            </div>
            <div>
              <CustomButton
                color="black"
                background="transparent"
                height="50px"
                borderRadius="20px"
                borderColor="rgb(137, 199, 74, 1)"
                width="280px"
                //   onClick={() => {
                //     Clear();
                //   }}
              >
                Clear
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{ ...containerStyle, ...noScrollbarStyle }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={headerStyle}>Order ID</TableCell>
                <TableCell sx={headerStyle}>Order Date</TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Product Price
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Product Price
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Charge
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Markup on Delivery Charge
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Amount to NearShopz Partner
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Delivery Amount to External Partner
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Platform Fee{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Product Price{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Packing Charges{" "}
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Product + Packing Charges
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Delivery
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  GST for Platform Fee
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Coupons
                </TableCell>
                <TableCell align="center" sx={headerStyle}>
                  Coupon Burn
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.no}>
                  <TableCell component="th" scope="row" sx={cellStyle2}>
                    #{row.orderNumber}
                  </TableCell>
                  <TableCell sx={cellStyle1}>{row.deliveryDate}</TableCell>
                  <TableCell align="center" sx={cellStyle}>
                    {`₹${(row.totalPrice).toFixed(2)}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={cellStyle1}
                    onClick={handleClickOpen}
                  >{`₹${row.totalPrice}`}</TableCell>
                  <TableCell align="center" sx={cellStyle}>
                  {`₹${(row.packingCharge).toFixed(2)}`}
                    
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle}>
                  {`₹${(row.deliveryCharge).toFixed(2)}`}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                  <TableCell align="center" sx={cellStyle4}>
                    {row.totalPrice}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "10px",
        }}
      >
        <Typography
          style={{
            textAlign: "right",
            marginTop: "2%",
            color: "black",
            fontWeight: "500",
          }}
          fontSize={HeaderNavigationFontSize_20}
        >
          Download Report
        </Typography>
        <img src={Download} style={{ marginBottom: "5px" }} />
      </div>
    </div>
  );
}

export default Reports;
