import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Button, ListItem, makeStyles, ListItemIcon } from "@material-ui/core";
import List from "@material-ui/core/List";
// import {
//   IconDashboard,
//   IconUser,
//   IconClipboard,
//   IconLogout,
//   IconZoomCheck,
//   IconBuildingStore,
//   IconHelp,
//   IconShoppingCartPlus,
//   IconHierarchy,
//   IconSquarePlus,
//   IconEdit,
//   IconDiscount2,
//   IconFileUpload,
// } from "@tabler/icons";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import GroupIcon from '@mui/icons-material/Group';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';

const useStyles = makeStyles((theme) => ({
  select: {
    color: "rgb(83,170,73)",
  },
  item: {
    margin: "0px 5px 21px",
    display: "flex",
    borderBottom: "none",
    paddingBottom: "3px",
    backgroundColor: "white",
    width: 260,
  },
  nested: {
    margin: "0px 4px -7px",
    marginTop: "-42px",
  },
  button: {
    color: theme.palette.text.secondary,
    backgroundColor: "white",
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      color: "#00C853",
      borderLeft: "5px solid #00C853",
      backgroundColor: "#ebedef",
    },
    "&:focus": {
      outline: "none",
      color: "#00C853",
      borderLeft: "5px solid #00C853",
      backgroundColor: "#ebedef",
    },
  },
  title: {
    marginRight: "-25px",
  },
  list: {
    width: "210px",
    height: "150%",
    // scroll: "auto",
    // overflowX: "hidden",
    // "@global": {
    //   "*::-webkit-scrollbar": {
    //     width: "0.4em",
    //   },
    //   "*::-webkit-scrollbar-track": {
    //     "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    //   },
    //   "*::-webkit-scrollbar-thumb": {
    //     backgroundColor: "white",
    //     outline: "1px solid slategrey",
    //   },
    // },
  },
  expand: {
    marginLeft: "37px",
  },
  collapse: {
    minHeight: "0px",
    height: "auto",
    paddingLeft: "50px",
    transitionDuration: "186ms",
  },
  activebutton: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    textTransform: "none",
    width: "100%",
    color: "#00C853",
    borderLeft: "5px solid #00C853",
    backgroundColor: "#ebedef",
    border: "none",
  },
}));

// constants
const icons = {
  DashboardIcon,
  PersonIcon,
  ContentPasteIcon,
  BookmarkBorderIcon,
  GroupIcon,
  HelpOutlineIcon,
  AddShoppingCartIcon,
  AccountTreeIcon,
  EditIcon,
  AddIcon,
  LocalOfferIcon,
  DriveFolderUploadIcon,
  PaymentsIcon,
  SettingsIcon,
  DescriptionIcon
};

const items = [
  {
    id: 0,
    open: false,
    icon: icons.DashboardIcon,
    title: "Dashboard",
  },
  {
    id: 1,
    open: false,
    icon: icons.PersonIcon,
    title: "Stores",
  },
  {
    id: 2,
    open: false,
    icon: icons.ContentPasteIcon,
    title: "Orders",
  },
  {
    id: 3,
    open: false,
    icon: icons.BookmarkBorderIcon,
    title: "Active Orders",
  },
  {
    id: 4,
    open: false,
    icon: icons.GroupIcon,
    title: "Users",
  },
  {
    id: 5,
    open: false,
    icon: icons.HelpOutlineIcon,
    title: "Complaints",
  },
  {
    id: 6,
    open: false,
    icon: icons.AddShoppingCartIcon,
    title: "New Products",
  },
  {
    id: 7,
    open: false,
    icon: icons.AccountTreeIcon,
    title: "Master Hierarchy",
  },
  {
    id: 8,
    open: false,
    icon: icons.AddIcon,
    title: "Add Master Products",
  },
  {
    id: 9,
    open: false,
    icon: icons.EditIcon,
    title: "Edit Master Products",
  },
  {
    id: 10,
    open: false,
    icon: icons.LocalOfferIcon,
    title: "Coupons",
  },
  {
    id: 11,
    open: false,
    icon: icons.DriveFolderUploadIcon,
    title: "Excel Upload",
  },
  {
    id: 12,
    open: false,
    icon: icons.PaymentsIcon,
    title: "Payment",
  },
  {
    id: 13,
    open: false,
    icon: icons.DescriptionIcon,
    title: "Reports",
  },
  {
    id: 14,
    open: false,
    icon: icons.SettingsIcon,
    title: "Settings",
  },
];

const NavItem = ({
  className,
  icon: Icon,
  selectedList,
  retailerId,
  onclick,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isActive, setActive] = React.useState(false);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setActive(!isActive);
  };
  const [state, setState] = React.useState({ items: items });
  const history = useHistory();
  const collapseClick = (id) => {
    setState((state) => ({
      ...state,
      items: state.items.map((itm) =>
        itm.id === id ? { ...itm, open: !itm.open } : itm
      ),
    }));
    history.push(`/home/${items[id].title.replace(/ /g, "")}`);
    return onclick();
  };

  return (
    <List className={classes.list} style={{ width: "100%" }}>
      {state.items.map((item, index) => {
        Icon = item.icon;
        return (
          <React.Fragment key={index}>
            <ListItem
              selected={selectedIndex === index}
              style={{ backgroundColor: "white" }}
              className={clsx(classes.item, className)}
              mainindex={index}
              disableGutters
            >
              <Button
                className={clsx(
                  classes.button,
                  selectedIndex === index && classes.activebutton
                )}
                onClick={(event) => {
                  collapseClick(item.id);
                  handleListItemClick(event, index);
                }}
              >
                {Icon && (
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                )}
                <span className={classes.title}>{item.title}</span>
              </Button>
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default NavItem;
