import { gql } from "@apollo/client";

export const UPDATE_RETAILER = gql`
  mutation (
    $retailerid: ID!
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $secondaryNumber: String
    $PANnumber: String
    $GSTnumber: String
    $emailAddress: String
    $aadharNumber: String
  ) {
    updateretailer(
      retailerid: $retailerid
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      PANnumber: $PANnumber
      emailAddress: $emailAddress
      GSTnumber: $GSTnumber
      aadharNumber: $aadharNumber
    ) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      aadharNumber
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation (
    $storeid: ID!
    $storeName: String
    $storeDescription: String
    $storeLogoUrl: String
    $storeLocation: String
    $storeCoordinates: locationInput
    $storeManagerFirstName: String
    $storeManagerLastName: String
    $storeManagerMobile: String
    $storeManagerPhone: String
    $storeStatus: String
    $deliveryType: [String]
    $deliveryCharge: [deliveryChargeInput]
    $deliveryMaxDistance: String
    $paymentSupported: [String]
    $deliveryDays: [deliveryDaysInput]
    $subscription: subscriptionInput
    $minAmount: Float
    $openingTime: String
    $closingTime: String
    $bankDetails: bankDetailsInput
    $holidays: [Int]
    $shopAsstTruncatedFlow: Boolean
    $razorPayAccountId: String
    $storeLocality: String
    $productMarkupPercent: Float
    $packingMarkupPercent: Float
    $deliveryChargeMarkupPercent: Float
    $platformFeePercent: Float
    $productGSTPercent: Float
    $packingGSTPercent: Float
    $deliveryChargeGSTPercent: Float
    $platformFeeGSTPercent: Float
    $storeDisplayImageUrl: String
    $packingCharge:Float
    $preparationTime:Float
  ) {
    updatestore(
      storeid: $storeid
      storeName: $storeName
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      storeLocation: $storeLocation
      storeCoordinates: $storeCoordinates
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeStatus: $storeStatus
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryCharge: $deliveryCharge
      deliveryMaxDistance: $deliveryMaxDistance
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      subscription: $subscription
      minAmount: $minAmount
      openingTime: $openingTime
      closingTime: $closingTime
      bankDetails: $bankDetails
      holidays: $holidays
      shopAsstTruncatedFlow: $shopAsstTruncatedFlow
      razorPayAccountId: $razorPayAccountId
      storeLocality: $storeLocality
      productMarkupPercent: $productMarkupPercent
      packingMarkupPercent: $packingMarkupPercent
      deliveryChargeMarkupPercent: $deliveryChargeMarkupPercent
      platformFeePercent: $platformFeePercent
      productGSTPercent: $productGSTPercent
      packingGSTPercent: $packingGSTPercent
      deliveryChargeGSTPercent: $deliveryChargeGSTPercent
      platformFeeGSTPercent: $platformFeeGSTPercent
      storeDisplayImageUrl:$storeDisplayImageUrl
      packingCharge:$packingCharge
      preparationTime:$preparationTime
    ) {
      id
      holidays
      storeCoordinates {
        type
        coordinates
      }
      storeLocation
      storeName
      storeDescription
      storeLogoUrl
      storeManagerFirstName
      storeManagerLastName
      storeManagerMobile
      storeManagerPhone
      storeStatus
      deliveryType
      packingCharge
      preparationTime
      deliveryMaxDistance
      deliveryCharge {
        kmRange
        charge
      }
      paymentSupported
      deliveryDays {
        deliveryDay
        T9amTO12pm
        T12pmTO3pm
        T3pmTO6pm
        T6pmTO9pm
      }
      storeWorking
      shopAsstTruncatedFlow
      storeLocality
      productMarkupPercent
      packingMarkupPercent
      deliveryChargeMarkupPercent
      platformFeePercent
      productGSTPercent
      packingGSTPercent
      deliveryChargeGSTPercent
      storeDisplayImageUrl
      platformFeeGSTPercent
      subscription {
        type
        startdate
        amount
      }
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(orderid: $orderid, stats: $stats) {
      id
      orderNumber
    }
  }
`;
export const REJECTED_STATUS = gql`
  mutation ($commentsSeller: String, $orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(
      commentsSeller: $commentsSeller
      orderid: $orderid
      stats: $stats
    ) {
      id
      orderNumber
      lastStatus
      stats {
        status
        created
        createdTime
      }
    }
  }
`;
export const DASHBOARD = gql`
  query {
    dashboard {
      totalCustomers
      totalStores
      totalShopAssistants
      totalAmountRecieved
      totalOrdersRecieved
      totalOrdersPlaced
      totalOrdersAccepted
      totalOrdersShopping
      totalOrdersReady
      totalOrdersCompleted
      totalOrdersCancelled
      totalOrdersRejected
      popularStore1
      popularStore2
      popularStore3
      popularStore4
      popularStore5
      popularStoreImg1
      popularStoreImg2
      popularStoreImg3
      popularStoreImg4
      popularStoreImg5
    }
  }
`;
export const NEW_PRODUCTS = gql`
  query {
    masterProductsApprovalList {
      id
      productname
      image {
        thumbnail
        primary
      }
      price
      status
    }
  }
`;
export const NEW_PRODUCT_DETAILS = gql`
  query ($productid: ID) {
    viewParticularMasterProduct(productid: $productid) {
      id
      productname
      desc
      brand
      price
      promoprice
      quantity
      displayrank
      uom
      mfgname
      barcode
      status
      image {
        primary
        secondary
        thumbnail
      }
      masterdepartment
      mastercategory
      mastercategory1
      mastercategory2
    }
  }
`;
export const DEPARTMENTS_LIST = gql`
  query {
    masterdepartments {
      id
      name
      parentid
      status
      businessTypeId
      childCategoryPresent
      displayrank
      categoryProductsCount
    }
  }
`;
export const CATEGORY_LIST = gql`
  query ($id: ID!) {
    mastercategory(id: $id) {
      id
      name
    }
  }
`;

export const ADD_NEW_PRODUCT = gql`
  mutation (
    $productId: ID!
    $productname: String
    $desc: String
    $mfgname: String
    $brand: String
    $price: String
    $promoprice: String
    $quantity: String
    $image: ImagesInput
    $status: String
    $uom: String
    $displayrank: Int
    $approved: Boolean
    $masterdepartment: ID
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    editParticuarMasterProduct(
      productId: $productId
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      image: $image
      status: $status
      displayrank: $displayrank
      approved: $approved
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      id
    }
  }
`;

export const DELETE_NEW_PRODUCT = gql`
  mutation ($productid: ID!) {
    deleteParticularMasterProduct(productid: $productid)
  }
`;

export const Master_Departments = gql`
  query {
    masterdepartments {
      id
      name
      parentid
      status
      businessTypeId
      childCategoryPresent
      displayrank
      categoryProductsCount
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation (
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String
    $promoprice: String
    $quantity: String
    $uom: String
    $image: ImagesInput
    $status: String!
    $displayrank: Int
    $masterdepartment: ID!
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    createMasterProduct(
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      image: $image
      status: $status
      displayrank: $displayrank
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      promoprice
      uom
      quantity

      image {
        primary
      }
      status
      barcode
    }
  }
`;
export const ADD_PRDT = gql`
  mutation (
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String
    $promoprice: String
    $quantity: String
    $uom: String
    $status: String!
    $displayrank: Int
    $masterdepartment: ID!
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    createMasterProduct(
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      status: $status
      displayrank: $displayrank
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      promoprice
      uom
      quantity
      image {
        primary
        secondary
        thumbnail
      }
      status
      barcode
    }
  }
`;
export const GET_PRODUCT_SEARCH = gql`
  query searchMasterProducts(
    $pagination: PaginationArg
    $status: String
    $searchKey: String
    $masterdepartment: ID
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    searchMasterProducts(
      pagination: $pagination
      status: $status
      searchKey: $searchKey
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      count
      hasNext
      items {
        id
        productname
        desc
        mfgname
        brand
        price
        promoprice
        quantity
        uom
        image {
          primary
          secondary
          thumbnail
        }
        masterdepartment
        mastercategory
        mastercategory1
        mastercategory2
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query ($productid: ID!) {
    viewParticularMasterProduct(productid: $productid) {
      id
      productname
      desc
      mfgname
      price
      promoprice
      quantity
      uom
      brand
      image {
        primary
        secondary
        thumbnail
      }
      status
      barcode
      displayrank
      masterdepartment
      mastercategory
      mastercategory1
      mastercategory2
    }
  }
`;
export const PRODUCT_SEARCH = gql`
  query searchMasterProducts(
    $pagination: PaginationArg
    $status: String
    $searchKey: String
    $masterdepartment: ID
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    searchMasterProducts(
      pagination: $pagination
      status: $status
      searchKey: $searchKey
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      count
      hasNext
      items {
        id
        productname
        desc
        mfgname
        brand
        price
        promoprice
        quantity
        uom
        status
        image {
          primary
          secondary
          thumbnail
        }
        masterdepartment
        mastercategory
        mastercategory1
        mastercategory2
      }
    }
  }
`;

export const UPDATE_PRODUCT_IMG = gql`
  mutation (
    $productid: ID!
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String!
    $promoprice: String
    $quantity: String
    $uom: String
    $image: Input
    $status: String!
    $displayrank: Int!
    $masterdepartment: ID!
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    editParticuarMasterProduct(
      productid: $productid
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      image: $image
      status: $status
      displayrank: $displayrank
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      barcode
      promoprice
      quantity
      uom

      image {
        primary
        secondary
        thumbnail
      }
      status
      displayrank
      storeid
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation (
    $productId: ID!
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String!
    $promoprice: String
    $quantity: String
    $uom: String
    $status: String!
    $displayrank: Int!
    $masterdepartment: ID!
    $mastercategory: ID
    $mastercategory1: ID
    $mastercategory2: ID
  ) {
    editParticuarMasterProduct(
      productId: $productId
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      status: $status
      displayrank: $displayrank
      masterdepartment: $masterdepartment
      mastercategory: $mastercategory
      mastercategory1: $mastercategory1
      mastercategory2: $mastercategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      barcode
      promoprice
      quantity
      uom

      image {
        primary
        secondary
        thumbnail
      }
      status
      displayrank
    }
  }
`;

export const UPDATE_PRODUCT_STATUS = gql`
  mutation ($productId: ID!, $status: String!) {
    editParticuarMasterProduct(productId: $productId, status: $status) {
      id
    }
  }
`;

export const createPercentageCoupon = gql`
  mutation (
    $type: Int!
    $couponCode: String!
    $custUseCount: Int!
    $minPurAmt: Float!
    $startDate: String!
    $endDate: String!
    $isUserDependent: Boolean!
    $disAmt: Float!
    $discountPercent: Float!
    $businessTypeId: Int
  ) {
    createCoupon(
      type: $type
      couponCode: $couponCode
      custUseCount: $custUseCount
      minPurAmt: $minPurAmt
      startDate: $startDate
      endDate: $endDate
      isUserDependent: $isUserDependent
      disAmt: $disAmt
      discountPercent: $discountPercent
      businessTypeId: $businessTypeId
    )
  }
`;

export const createFlatCoupon = gql`
  mutation (
    $type: Int!
    $couponCode: String!
    $custUseCount: Int!
    $minPurAmt: Float!
    $startDate: String!
    $endDate: String!
    $disAmt: Float!
    $isUserDependent: Boolean!
    $businessTypeId: Int
  ) {
    createCoupon(
      type: $type
      couponCode: $couponCode
      custUseCount: $custUseCount
      minPurAmt: $minPurAmt
      startDate: $startDate
      endDate: $endDate
      disAmt: $disAmt
      isUserDependent: $isUserDependent
      businessTypeId: $businessTypeId
    )
  }
`;
export const createDeliveryFreeCoupon = gql`
  mutation (
    $type: Int!
    $couponCode: String!
    $custUseCount: Int!
    $startDate: String!
    $endDate: String!
    $minPurAmt: Float!
    $isUserDependent: Boolean!
    $businessTypeId: Int
  ) {
    createCoupon(
      type: $type
      couponCode: $couponCode
      custUseCount: $custUseCount
      minPurAmt: $minPurAmt
      startDate: $startDate
      endDate: $endDate
      isUserDependent: $isUserDependent
      businessTypeId: $businessTypeId
    )
  }
`;

export const LISTCOUPONS = gql`
  query {
    listAllCoupons {
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      disAmt
      appliedCount
      description
      status
    }
  }
`;

export const LISTUSERCOUPONS = gql`
  query {
    listUserSpecificCoupons {
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      disAmt
      endDate
      description
      status
    }
  }
`;

export const LISTALLCOUPONS = gql`
  query {
    listAllCoupons {
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      appliedCount
      disAmt
      endDate
      description
      status
    }
  }
`;

export const ASSIGN_COUPON = gql`
  mutation ($couponId: ID!, $userId: ID!, $userCouponCount: Int!) {
    assignCouponToUser(
      couponId: $couponId
      userId: $userId
      userCouponCount: $userCouponCount
    )
  }
`;
export const EDIT_COUPON = gql`
  mutation ($couponId: ID!, $status: String!) {
    editCoupon(couponId: $couponId, status: $status) {
      id
      type
      name
      couponCode
      status
      isUserDependent
    }
  }
`;

export const GET_BUSINESSTYPES = gql`
  query {
    businessType {
      id
      name
    }
  }
`;

export const GETLINK = gql`
  mutation ($storeid: ID!, $openStore: Boolean!) {
    shareMyLink(storeid: $storeid, openStore: $openStore)
  }
`;
export const getShopAssistantDetails = gql`
  query ($shopAssistantId: ID!) {
    viewParticularShopAssistant(shopAssistantId: $shopAssistantId) {
      id
      firstName
      lastName
      phoneNumber
    }
  }
`;
export const Multiple_Upload = gql`
  mutation ($files: [Upload], $type: Int!, $storeid: ID!, $fileName: [String]) {
    uploadMultipleFiles(
      files: $files
      type: $type
      storeid: $storeid
      fileName: $fileName
    ) {
      ETag
      Location
      Key
      Bucket
    }
  }
`;
