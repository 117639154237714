// import React, { useEffect, useState } from 'react'
// import GoogleMapReact from "google-map-react";
// import {DirectionsRenderer} from "@react-google-maps/api"
// import Marker from '../../Marker';
// import { useLocation } from 'react-router-dom';
// function GoogleMapOrder(props) {
//     const location = useLocation();
//   const orderid = location.state.orderid;
//     useEffect(() => {
//         props.getSearchDetails(orderid);
//         console.log("two", props.getSearchDetails(orderid));
//       }, []);
    
//       const readyOrderDetails = ("props", props && props.searchDetails);
//       console.log("readyOrderDetails", readyOrderDetails);
//       const [center, setCenter] = useState({
//         lat: readyOrderDetails?.storeid?.storeCoordinates
//             ? Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1])
//             : 10.850516,
//         lng: readyOrderDetails?.storeid?.storeCoordinates
//             ? Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0])
//             : 76.27108,
//     });
//     {readyOrderDetails?.userid?.address.map((each)=>{
//         console.log("one",each.locationLat)
//         console.log("two",each.locationLng)
//     })}
//     //  const [customerLocation,setCustomerLocation]= useState({
//     //     lat:readyOrderDetails?.userid
//     //      && Number(readyOrderDetails?.userid?.address[0]?.locationLat),
//     //      lng:readyOrderDetails?.userid && (
//     //         Number(readyOrderDetails?.userid?.address[0]?.locationLng)
//     //      ) 
//     //  })
//     const [customerLocation,setCustomerLocation] = useState({lat:9.968843,lng:76.290868})
//      const [directionResponse,setDirectionResponse] = useState()
//      console.log("lat",readyOrderDetails?.userid.address[0].locationLat)
//      console.log("lng",readyOrderDetails?.userid.address[0].locationLng)
//      console.log("location",customerLocation)
//     // const directionsService = new window.google.maps.DirectionsService()
//     //     const results = directionsService.route({
//     //         origin:center,
//     //         destination:customerLocation,
//     //         travelMode: window.google.maps.TravelMode.DRIVING
//     //     })
//     //      setDirectionResponse(results)
//     useEffect(() => {
//         const directionsService = new window.google.maps.DirectionsService();
//         directionsService.route({
//             origin: center,
//             destination: customerLocation,
//             travelMode: window.google.maps.TravelMode.DRIVING
//         }, (result, status) => {
//             if (status === window.google.maps.DirectionsStatus.OK) {
//                 setDirectionResponse(result);
//             } else {
//                 console.error(`error fetching directions ${result}`);
//             }
//         });
//     }, [center, customerLocation]);
    
//     console.log("prop",props)
//   return (
//     <div style={{ height: "100vh", width: "100%" }}>
//     <GoogleMapReact
//                     bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }}
//                     //bootstrapURLKeys={{ key: "AIzaSyA1Tzy_rLkPkeAkyk8yHPh-5GBU2NxUm5Y" }}
//                      defaultCenter={center}
//                     defaultZoom={15}
//                     // onClick={changeMarkerPosition}
//     >
//     <Marker draggable={true} lat={center.lat} lng={center.lng}  text="My Marker" />
//     {directionResponse && <DirectionsRenderer directions={directionResponse}/>}
//     </GoogleMapReact>
//     </div>
//   )
// }

// export default GoogleMapOrder

// import React, { useEffect, useState } from 'react';
// import GoogleMapReact from 'google-map-react';
// import { DirectionsRenderer } from '@react-google-maps/api';
// import Marker from '../../Marker';
// import { useLocation } from 'react-router-dom';

// function GoogleMapOrder(props) {
//   const location = useLocation();
//   const orderid = location.state.orderid;

//   useEffect(() => {
//     props.getSearchDetails(orderid);
//   }, [orderid]);

//   const readyOrderDetails = props.searchDetails;
//   const [center, setCenter] = useState({
    // lat: readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1] || 10.850516,
    // lng: readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0] || 76.27108,
//   });

//   const customerLocation = {
    // lat: readyOrderDetails?.userid?.address[0]?.locationLat || 9.968843,
    // lng: readyOrderDetails?.userid?.address[0]?.locationLng || 76.290868,
//   };
//  console.log("customer",customerLocation)
//  console.log("center",center)
//   const [directionResponse, setDirectionResponse] = useState(null);
//  console.log("directionResponse",directionResponse)
//   useEffect(() => {
//     const directionsService = new window.google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin: center,
//         destination: customerLocation,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//       },
//       (result, status) => {
//         if (status === window.google.maps.DirectionsStatus.OK) {
//           setDirectionResponse(result);
//         } else {
//           console.error(`Error fetching directions: ${status}`);
//         }
//       }
//     );
//   }, []);

//   return (
//     <div style={{ height: '100vh', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }} // Replace with your API key
//         defaultCenter={center}
//         defaultZoom={15}
//       >
//         <Marker draggable={true} lat={center.lat} lng={center.lng} text="My Marker" />
//         {directionResponse && <DirectionsRenderer directions={directionResponse} />}
//       </GoogleMapReact>
//     </div>
//   );
// }

// export default GoogleMapOrder;
// import React, { useState, useEffect } from "react";
// import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
// import { useLocation } from "react-router-dom";
 
// const defaultLocation = { lat: 40.756795, lng: -73.954298 };
 
// const GoogleMapOrder = (props) => {
//     const location = useLocation();
//   const orderid = location.state.orderid;

//   useEffect(() => {
//     props.getSearchDetails(orderid);
//   }, [orderid]);

//   const readyOrderDetails = props.searchDetails;
//   const [directions, setDirections] = useState(null);

//   const destination = { lat: readyOrderDetails?.userid?.address[0]?.locationLat || 9.968843,
//     lng: readyOrderDetails?.userid?.address[0]?.locationLng || 76.290868, };
// const origin = {  lat: readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1] || 10.850516,
//     lng: readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0] || 76.27108 };

//   useEffect(() => {
//     const directionsService = new window.google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin,
//         destination,
//         travelMode: window.google.maps.TravelMode.DRIVING
//       },
//       (result, status) => {
//         if (status === window.google.maps.DirectionsStatus.OK) {
//           setDirections(result);
//         } else {
//           console.error(`Error fetching directions: ${status}`);
//         }
//       }
//     );
//   }, []);
 
//   return (
// <div>
// <GoogleMap
//         center={defaultLocation}
// bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }}
//         zoom={5}
//         mapContainerStyle={{ height: "400px", width: "800px" }}
// >
//         {directions && <DirectionsRenderer directions={directions} />}
// </GoogleMap>
// </div>
//   );
// };
 
// export default GoogleMapOrder;

//AIzaSyCbMzT9FZ_YAEyaQWslSjd2hqGAqUD9zWE

import React, { useState, useEffect } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
//import { useLocation } from "react-router-dom";
//import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// const defaultLocation = { lat: 40.756795, lng: -73.954298 };

const GoogleMapOrder = (props) => {
  const orderid = localStorage.getItem('orderID') 

  useEffect(() => {
    props.getOrderDetails(orderid);
  }, [orderid]);

  const readyOrderDetails = props?.OrderDetails &&
  props?.OrderDetails?.orderdetails;
  console.log("ambane",readyOrderDetails)
  const [directions, setDirections] = useState(null); 
//   lat:8.0843512,lng:77.54950190000001
// const destination = {
//   lat: Number(readyOrderDetails?.userid?.address[0]?.locationLat) || 8.0843512,
//   lng: Number(readyOrderDetails?.userid?.address[0]?.locationLng) || 77.54950190000001,
// };
const destination = {
  lat: Number(readyOrderDetails?.deliveryLat),
  lng: Number(readyOrderDetails?.deliveryLng)
}
console.log("destination",destination)
const origin = {
  lat: Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[1]) || 10.850516,
  lng: Number(readyOrderDetails?.storeid?.storeCoordinates?.coordinates[0]) || 76.27108,
};
console.log("origin",origin)
  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  }, []);
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`;
  localStorage.setItem('googleMapsUrl',googleMapsUrl)
  console.log("url",googleMapsUrl);
  
  return (
    <div >
      <GoogleMap
        center={origin} // Set the center to the user's current location
        zoom={5}
        mapContainerStyle={{ height: "100vh", width: "100%" }}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
      
    </div>
  );
};

export default GoogleMapOrder;

