import { gql } from "@apollo/client";

export const NOTIFICATION_STATUS = gql`
  mutation (
    $storeid: ID!
    $orderid: ID!
    $status: String!
    $deliveryDate: String!
    $deliveryTime: String!
    $orderNumber: Int!
    $shopassistantId: ID
  ) {
    scheduleAlertOnStatus(
      storeid: $storeid
      orderid: $orderid
      status: $status
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      orderNumber: $orderNumber
      shopassistantId: $shopassistantId
    )
  }
`;

export const CHANGE_TO_ACTIVE_STATUS = gql`
  mutation ($orderid: ID!, $preparationTime: Int!) {
    changeOrderStatusToShopping(
      orderid: $orderid
      preparationTime: $preparationTime
    ) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      discountPrice
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
      stats {
        createdTime
        created
        status
        timestamp
      }
    }
  }
`;
export const CHANGE_ORDER_STATUS_TO_READY = gql`
  mutation ($orderid: ID!, $products: [ProductsInput]) {
    changeOrderStatusToReady(orderid: $orderid, products: $products) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryBy
      deliveryTip
      total
      totalPayable
      preparationTime
      specialInstructions
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
      stats {
        createdTime
        created
        status
        timestamp
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_DELIVERY = gql`
  mutation ($orderid: ID!, $packingImages: [String]) {
    changeToOutForDelivery(orderid: $orderid, packingImages: $packingImages) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      deliveryBy
      total
      totalPayable
      packingImages
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
      stats {
        createdTime
        created
        status
        timestamp
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_COMPLETED = gql`
  mutation ($orderid: ID!, $stats: StatusInput!) {
    updateOrderStatus(orderid: $orderid, stats: $stats) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      deliveryBy
      total
      totalPayable
      preparationTime
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
      stats {
        createdTime
        created
        status
        timestamp
      }
    }
  }
`;
export const ORDER_NEW_UPLOAD = gql`
  mutation (
    $file: Upload
    $size: Int
    $filename: String
    $type: Int
    $orderid: String
  ) {
    imageEditAndUpload(
      file: $file
      size: $size
      filename: $filename
      type: $type
      orderid: $orderid
    ) {
      ETag
      Location
    }
  }
`;

export const GET_ALL_NOTPROCESSED_SETTLEMENTS = gql`
  query ($pagination: PaginationArg, $orderNumber: Float) {
    listAllNotProcessedSettlements(
      pagination: $pagination
      orderNumber: $orderNumber
    ) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        deliveryCharge
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        deliveryBy
        amountForSeller
        amountForCustomer
        amountForNearshopz
        amountForNearshopzDeliveryPerson
        amountForExternalDeliveryPerson
        extraAmountByNearshopz
        amountAdjustedManually
        editedAmountForSeller
        editedAmountForNearshopz
        razorPayFeesAndTax
        editedAmountForNearshopzDeliveryPerson
        editedRazorPayFeesAndTax
        editedAmountForExternalDeliveryPerson
        paymentStatus
        editedExtraAmountByNearshopz
        forceCompleteSettlement
        razorpayPaymentId
        sellerReimbursement

        editedSellerReimbursement
        totalPriceDelivery
      }
      hasNext
    }
  }
`;

export const GET_ALL_COMPLETED_SETTLEMENTS = gql`
  query ($pagination: PaginationArg) {
    listAllCompletedSettlements(pagination: $pagination) {
      count
      items {
        orderNumber
        storeid {
          id
        }
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        deliveryCharge
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
          timestamp
        }
        transferSettlementStatus
        totalPayable
        deliveryBy
        amountForSeller
        paymentStatus
        amountForCustomer
        razorpayPaymentId
        amountForNearshopz

        amountForNearshopzDeliveryPerson

        amountForExternalDeliveryPerson

        extraAmountByNearshopz
        razorPayFeesAndTax
        amountAdjustedManually

        editedAmountForSeller
        editedRazorPayFeesAndTax
        editedAmountForNearshopz

        editedAmountForNearshopzDeliveryPerson

        editedAmountForExternalDeliveryPerson

        editedExtraAmountByNearshopz
        totalPriceDelivery
        forceCompleteSettlement

        sellerReimbursement

        editedSellerReimbursement
      }
      hasNext
    }
  }
`;
export const GET_COMPLETEDPAYMENT_SEARCH = gql`
  query (
    $pagination: PaginationArg
    $storeid: ID
    $dateFrom: String
    $dateTo: String
    $orderNumber: Float
  ) {
    searchCompletedSettlementsWithFilters(
      pagination: $pagination
      storeid: $storeid
      dateFrom: $dateFrom
      dateTo: $dateTo
      orderNumber: $orderNumber
    ) {
      count
      items {
        orderNumber
        totalPrice
        deliveryDate
        deliveryTime
        mobileNumber
        customerName
        id
        stats {
          status
          created
          createdTime
        }
        transferSettlement {
          status
          created
          createdTime
        }
        transferSettlementStatus
        totalPayable
        deliveryBy
        amountForSeller
        paymentStatus
        amountForCustomer
        razorpayPaymentId
        amountForNearshopz

        amountForNearshopzDeliveryPerson
        razorPayFeesAndTax
        amountForExternalDeliveryPerson

        extraAmountByNearshopz

        amountAdjustedManually

        editedAmountForSeller
        editedRazorPayFeesAndTax
        editedAmountForNearshopz

        editedAmountForNearshopzDeliveryPerson

        editedAmountForExternalDeliveryPerson

        editedExtraAmountByNearshopz
        forceCompleteSettlement

        sellerReimbursement

        editedSellerReimbursement
        totalPriceDelivery
      }

      hasNext
    }
  }
`;
export const CHECK_SETTELEMENT_STATUS = gql`
  mutation ($orderId: ID, $forceCompleteSettlement: Boolean) {
    checkSettlementStatus(
      orderId: $orderId
      forceCompleteSettlement: $forceCompleteSettlement
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transferSettlementStatus
      totalPayable
      deliveryBy
      amountForSeller
      paymentStatus
      amountForCustomer

      amountForNearshopz
      razorpayPaymentId
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson

      extraAmountByNearshopz

      amountAdjustedManually

      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz

      editedAmountForNearshopzDeliveryPerson

      editedAmountForExternalDeliveryPerson

      editedExtraAmountByNearshopz
      forceCompleteSettlement

      sellerReimbursement

      editedSellerReimbursement
      totalPriceDelivery
    }
  }
`;
export const UPDATE_SETTELEMENT_DETAILS = gql`
  mutation (
    $orderid: ID
    $editedAmountForSeller: Float
    $editedAmountForNearshopz: Float
    $editedAmountForNearshopzDeliveryPerson: Float
    $editedAmountForExternalDeliveryPerson: Float
    $editedExtraAmountByNearshopz: Float
    $settlementComment: String
    $editedSellerReimbursement: Float
  ) {
    updateSettlementDetails(
      orderid: $orderid
      editedAmountForSeller: $editedAmountForSeller
      editedAmountForNearshopz: $editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson: $editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson: $editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz: $editedExtraAmountByNearshopz
      settlementComment: $settlementComment
      editedSellerReimbursement: $editedSellerReimbursement
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transferSettlementStatus
      totalPayable

      amountForSeller
      paymentStatus
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceDelivery
      razorpayPaymentId
    }
  }
`;

export const CREATE_TRANSFER = gql`
  mutation ($orderId: ID!, $transferTo: String!, $razorPayAccountId: String) {
    createTransfer(
      orderId: $orderId
      transferTo: $transferTo
      razorPayAccountId: $razorPayAccountId
    ) {
      orderNumber
      totalPrice
      deliveryDate
      deliveryTime
      mobileNumber
      customerName
      id
      stats {
        status
        created
        createdTime
      }
      transferSettlement {
        status
        created
        createdTime
      }
      transferSettlementStatus
      totalPayable
      deliveryBy
      amountForSeller
      paymentStatus
      amountForCustomer
      amountForNearshopz
      amountForNearshopzDeliveryPerson
      razorPayFeesAndTax
      amountForExternalDeliveryPerson
      extraAmountByNearshopz
      amountAdjustedManually
      editedAmountForSeller
      editedRazorPayFeesAndTax
      editedAmountForNearshopz
      editedAmountForNearshopzDeliveryPerson
      editedAmountForExternalDeliveryPerson
      editedExtraAmountByNearshopz
      forceCompleteSettlement
      sellerReimbursement
      editedSellerReimbursement
      totalPriceDelivery
      razorpayPaymentId
    }
  }
`;

export const GET_SETTINGS = gql`
  query {
    getSettings {
      id
      defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent
      restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent
      platformFeeGSTPercent
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation (
    $id: ID
    $defaultRestaurantProductMarkupPercent: Float
    $defaultPackingMarkupPercent: Float
    $defaultDeliveryChargeMarkupPercent: Float
    $defaultPlatformFeePercent: Float
    $restaurantProductPriceGSTPercent: Float
    $restaurantPackingChargeGSTPercent: Float
    $supermarketPackingChargeGSTPercent: Float
    $deliveryChargeGSTPercent: Float
    $platformFeeGSTPercent: Float
  ) {
    updateAdminSettings(
      id: $id
      defaultRestaurantProductMarkupPercent: $defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent: $defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent: $defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent: $defaultPlatformFeePercent
      restaurantProductPriceGSTPercent: $restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent: $restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent: $supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent: $deliveryChargeGSTPercent
      platformFeeGSTPercent: $platformFeeGSTPercent
    ) {
      id
      defaultRestaurantProductMarkupPercent
      defaultPackingMarkupPercent
      defaultDeliveryChargeMarkupPercent
      defaultPlatformFeePercent
      restaurantProductPriceGSTPercent
      restaurantPackingChargeGSTPercent
      supermarketPackingChargeGSTPercent
      deliveryChargeGSTPercent
      platformFeeGSTPercent
    }
  }
`;
export const UPDATE_DELIVERYBY = gql`
  mutation ($orderid: ID $deliveryBy: Int) {
    updateDeliveryBy(orderid: $orderid deliveryBy: $deliveryBy) {
      id
      orderNumber
      deliveryBy
      paymentStatus
    }
  }
`;
