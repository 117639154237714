import client from "../../../Configurations/apollo";
import { gql } from "@apollo/client";
import {
  UPDATE_STORE,
  UPDATE_RETAILER,
  CHANGE_STATUS,
  REJECTED_STATUS,
  DASHBOARD,
  NEW_PRODUCTS,
  NEW_PRODUCT_DETAILS,
  DEPARTMENTS_LIST,
  CATEGORY_LIST,
  ADD_NEW_PRODUCT,
  DELETE_NEW_PRODUCT,
  Master_Departments,
  GET_CATEGORY,
  ADD_PRODUCT,
  ADD_PRDT,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_SEARCH,
  PRODUCT_SEARCH,
  UPDATE_PRODUCT_IMG,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_STATUS,
  createPercentageCoupon,
  createFlatCoupon,
  createDeliveryFreeCoupon,
  LISTUSERCOUPONS,
  LISTALLCOUPONS,
  ASSIGN_COUPON,
  EDIT_COUPON,
  GET_BUSINESSTYPES,
  GETLINK,
  getShopAssistantDetails,
  Multiple_Upload,
} from "./gqlQuery";
import { shopAssistantDetails } from "../StoreDetails";
const GET_SELLER_DETAIL = gql`
  query ($retailerid: ID!) {
    retailerdetails(retailerid: $retailerid) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      GSTurl
      aadharUrl
      aadharNumber
      packingCharge
      preparationTime
      stores {
        fssaiUrl
        licenseUrl
        fssaiNo
        subscription {
          type
          startdate
          amount
        }
        id
        storeLocation
        storeName
        storeDescription
        storeManagerFirstName
        storeManagerLastName
        storeCoordinates {
          coordinates
        }
        storeLogoUrl
        deliveryType
        storeDeliveryPerson
        deliveryMaxDistance
        deliveryCharge {
          kmRange
          charge
        }
        paymentSupported
        deliveryDays {
          deliveryDay
          T9amTO12pm
          T12pmTO3pm
          T3pmTO6pm
          T6pmTO9pm
        }
        storeManagerMobile
        storeManagerPhone
      }
    }
  }
`;
export const setActiveOrder = (params) => {
  let ActiveOrder = { params };

  return {
    type: "ActiveOrder_Search",
    payload: {
      ActiveOrder,
    },
  };
};

export const setLiveOrder = (params) => {
  return {
    type: "SET_LIVE_ORDER",
    payload: params,
  };
};

export const deleteLiveOrder = () => {
  return {
    type: "DELETE_LIVE_ORDER",
  };
};

export const setUser = (params) => {
  let Users = { params };

  return {
    type: "User_Search",
    payload: {
      Users,
    },
  };
};
export const setOrder = (params) => {
  let Order = { params };

  return {
    type: "Order_Search",
    payload: {
      Order,
    },
  };
};
export const clearSearchOrderResult = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCHORDER_RESULT",
    });
  };
};

export const postStatusChange = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGING_STATUS",
    });
    client
      .mutate({
        mutation: CHANGE_STATUS,
        variables: {
          orderid: params.orderid,
          stats: { status: params.status },
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "CHANGED_STATUS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "STATUS_CHANGE_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "STATUS_CHANGE_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getSellerDetail = (params) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "SELLER_VIEW_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "SELLER_VIEW_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "SELLER_VIEW_ERROR" });
    }
    const success = await client.query({
      query: GET_SELLER_DETAIL,
      variables: {
        retailerid: params,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
export const rejectedStatusHandler = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGING_TO_REJECTED",
    });
    client
      .mutate({
        mutation: REJECTED_STATUS,
        variables: {
          commentsSeller: params.commentsSeller,
          orderid: params.orderid,
          stats: params.stats,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "CHANGED_TO_REJECTED",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "REJECTION_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REJECTION_FAILED",
          payload: err.message,
        });
      });
  };
};
export const multipleDelete = () => {
  return {
    type: "MULTIPLE_DELETE",
  };
};
export const textEditChanges = (params) => {
  return {
    type: "TEXT_EDIT_CHANGE",
    payload: {
      ...params.params,
      forAdmin: {
        ...params.params.forAdmin,
        [params.e.target.name]: params.e.target.value,
      },
    },
  };
};

export const textStoreChanges = (params) => {
  return {
    type: "TEXT_STORE_CHANGE",
    payload: {
      ...params.params,
      [params.e.target.name]: params.e.target.value,
    },
  };
};

export const handleStatusChange = (params1, params2) => {
  let { name, value } = params2;
  let newDetail = { ...params1 };
  newDetail[name] = value;
  return {
    type: "STATUS_STORE_CHANGE",
    payload: {
      ...params1,
      ...newDetail,
    },
  };
};

export const handleShopAssistant = (params1, params2) => {
  return {
    type: "SHOPASSISTANT_FLOW_UPDATE",
    payload: {
      ...params2,
      shopAsstTruncatedFlow: params1,
    },
  };
};

export const updatestore = (params) => {
  let {
    storeName,
    storeDescription,
    storeLogoUrl,
    storeLocation,
    storeManagerFirstName,
    storeManagerLastName,
    storeManagerMobile,
    storeStatus,
    storeManagerPhone,
    deliveryType,
    deliveryCharge,
    paymentSupported,
    subscription,
    deliveryDays,
    deliveryMaxDistance,
    closingTime,
    openingTime,
    minAmount,
    bankDetails,
    razorPayAccountId,
    storeCoordinates,
    holidays,
    shopAsstTruncatedFlow,
    storeLocality,
    productMarkupPercent,
    packingMarkupPercent,
    deliveryChargeMarkupPercent,
    platformFeePercent,
    productGSTPercent,
    packingGSTPercent,
    deliveryChargeGSTPercent,
    platformFeeGSTPercent,
    packingCharge,
    preparationTime,

  } = params;
  let deliveryRate =
    deliveryCharge &&
    deliveryCharge.map((rate) => ({
      kmRange: rate.kmRange,
      charge: rate.charge,
    }));
  let deliverDay =
    deliveryDays &&
    deliveryDays.map((day) => ({
      T9amTO12pm: day.T9amTO12pm,
      T12pmTO3pm: day.T12pmTO3pm,
      T3pmTO6pm: day.T3pmTO6pm,
      T6pmTO9pm: day.T6pmTO9pm,
      deliveryDay: day.deliveryDay,
    }));

  let storeDisplayImageUrl = localStorage.getItem("displayImage");
  console.log("imgae");

  return (dispatch) => {
    dispatch({
      type: "EDIT_STORE_WAITING",
    });
    client
      .mutate({
        mutation: UPDATE_STORE,

        variables: {
          storeid: localStorage.getItem(`storeid`),
          storeName: storeName,
          storeDescription: storeDescription,
          storeLogoUrl: storeLogoUrl,
          
          storeLocation: storeLocation,
          storeCoordinates: {
            type: storeCoordinates.type,
            coordinates: [
              Number(storeCoordinates.coordinates[0]),
              Number(storeCoordinates.coordinates[1]),
            ],
          },
          storeManagerFirstName: storeManagerFirstName,
          storeManagerLastName: storeManagerLastName,
          storeManagerMobile: storeManagerMobile,
          storeStatus: storeStatus,
          storeManagerPhone: storeManagerPhone,
          deliveryType: deliveryType,
          deliveryCharge: deliveryRate,
          deliveryMaxDistance: `${deliveryMaxDistance}`,
          paymentSupported: paymentSupported,
          deliveryDays: deliverDay,
          holidays: holidays,
          razorPayAccountId: razorPayAccountId,
          subscription: {
            type: Number(subscription.type),
            amount: Number(subscription.amount),
            startdate: subscription.startdate,
          },
          closingTime: closingTime,
          openingTime: openingTime,

          minAmount: Number(minAmount),
          shopAsstTruncatedFlow: shopAsstTruncatedFlow,
          bankDetails: {
            accountHolderName: bankDetails.accountHolderName,
            bankAccountNumber: bankDetails.bankAccountNumber,
            IFSCcode: bankDetails.IFSCcode,
          },
          storeLocality: storeLocality,
          productMarkupPercent: parseFloat(productMarkupPercent),
          packingMarkupPercent: parseFloat(packingMarkupPercent),
          deliveryChargeMarkupPercent: parseFloat(deliveryChargeMarkupPercent),
          platformFeePercent: parseFloat(platformFeePercent),
          productGSTPercent: parseFloat(productGSTPercent),
          packingGSTPercent: parseFloat(packingGSTPercent),
          deliveryChargeGSTPercent: parseFloat(deliveryChargeGSTPercent),
          platformFeeGSTPercent: parseFloat(platformFeeGSTPercent),
          packingCharge:parseFloat(packingCharge),
          preparationTime:parseFloat(preparationTime),
          storeDisplayImageUrl: storeDisplayImageUrl,
        },
        // },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "EDIT_STORE_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "EDIT_STORE_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_STORE_FAILED",
          payload: err.message,
        });
      });
  };
};

export const checkBoxEdit = (e, params) => {
  let editedValue = { ...params };
  if (e.target.name in editedValue) {
    if (e.target.checked) {
      if (e.target.name === "holidays")
        editedValue[e.target.name].push(Number(e.target.value));
      else editedValue[e.target.name].push(e.target.value);
    } else {
      if (e.target.name === "holidays") {
        let indexValue = editedValue[e.target.name].indexOf(
          Number(e.target.value)
        );
        editedValue[e.target.name].splice(indexValue, 1);
      } else {
        let indexValue = editedValue[e.target.name].indexOf(e.target.value);
        editedValue[e.target.name].splice(indexValue, 1);
      }
    }
  } else {
    let newFieldAdd = [];
    if (e.target.name === "holidays") newFieldAdd.push(Number(e.target.value));
    else newFieldAdd.push(e.target.value);
    return { ...editedValue, newFieldAdd };
  }
  return {
    type: "CHECKBOX_EDIT",
    payload: {
      //   data: {
      //     retailerdetails: {
      ...params,
      editedValue,
      //     },
      //   },
    },
  };
};

export const scheduleEdit = (params) => {
  let timing;
  if (params.scheduleDetail.deliveryDays[params.key][params.timing] === "Yes") {
    timing = "No";
  } else {
    timing = "Yes";
  }
  let { deliveryDays } = params.scheduleDetail;
  deliveryDays[params.key][params.timing] = timing;
  let newSchedule = [...deliveryDays];
  return {
    type: "SCHEDULE_EDIT",
    payload: {
      ...params.scheduleDetail,
      deliveryDays: newSchedule,
    },
  };
};

export const postNewUrl = (params) => {
  let storeurl = { ...params.details.stores[0].storeLogoUrl };
  storeurl = params.url;
  let newUrlDetail = { ...params.details };
  newUrlDetail.stores[0].storeLogoUrl = storeurl;
  return {
    type: "NEW_IMAGE_URL",
    payload: {
      data: {
        retailerdetails: {
          ...newUrlDetail,
        },
      },
    },
  };
};

// export const getNewLocation = (params) => {
//   console.log(params)
//   let storePlace = {
//     ...params.details.retailerdetails.stores[0].storeLocation,
//   };
//   storePlace = params.address;
//   let newDetail = { ...params.details.retailerdetails };
//   newDetail.stores[0].storeLocation = storePlace;
//   let coordinate = [
//     ...params.details.retailerdetails.stores[0].storeCoordinates.coordinates,
//   ];
//   coordinate[0] = params.coordinates.lng;
//   coordinate[1] = params.coordinates.lat;
//   newDetail.stores[0].storeCoordinates.coordinates = [params.coordinates.lng, params.coordinates.lat];

//   return {
//     type: "EDIT_LOCATION",
//     payload: {
//       data: {
//         retailerdetails: {
//           ...newDetail,
//         },
//       },
//     },
//   };
// };

// export const getNewCoordinates = (params) => {
//   let detailsNewCoordinate = { ...params.details };
//   let coordinate = [...params.details.stores[0].storeCoordinates.coordinates];
//   coordinate[0] = params.geocode.lng;
//   coordinate[1] = params.geocode.lat;
//   detailsNewCoordinate.stores[0].storeCoordinates.coordinates = coordinate;
//   return {
//     type: "NEW_COORDINATES",
//     payload: {
//       data: {
//         retailerdetails: {
//           ...detailsNewCoordinate,
//         },
//       },
//     },
//   };
// };

export const getEditedLocation = (params) => {
  return {
    type: "EDIT_LOCATION",
    payload: {
      location: params.address,
      latitude: params.coordinates.lat,
      longitude: params.coordinates.lng,
    },
  };
};

export const doorDeliveryEdit = (params) => {
  let storeDeliveryPerson = { ...params.details.stores[0].storeDeliveryPerson };
  storeDeliveryPerson = params.value;
  let newDetail = { ...params.details };
  newDetail.stores[0].storeDeliveryPerson = storeDeliveryPerson;
  return {
    type: "NEW_DELIVERY_DETAIL",
    payload: {
      data: {
        retailerdetails: {
          ...newDetail,
        },
      },
    },
  };
};

export const changeMaxDistance = (params) => {
  console.log("this is params from chnagemax distace------", params);
  return {
    type: "NEW_MAX_DISTANCE",
    payload: {
      ...params.details,
      deliveryMaxDistance: params.value,
    },
  };
};
export const changePreperationTime = (params) => {
  console.log("this is params from changeTime distance------", params);
  return {
    type: "NEW_PREPERATIONTIME",
    payload: {
      ...params.params,
      [params.e.target.name]: params.e.target.value,
    },
  };
};
//NEW_PACKINGCHARGE
export const changePackingCharge = (params) => {
  console.log("this is params from changeTime distance------", params);
  return {
    type: "NEW_PACKINGCHARGE",
    payload: {
      ...params.params,
      [params.e.target.name]: params.e.target.value,
    },
  };
};

// export const editDeliveryCharge = (params) => {
// 	let { deliveryCharge } = params.details
// 	let newCharges = [...deliveryCharge];
// 	let newAddedDetails = { ...params.details };
// 	newCharges[params.index].charge = params.value;
// 	newAddedDetails.stores[0].deliveryCharge = newCharges;
// 	return {
// 		type: "NEW_EDIT_CHARGES",
// 		payload: {
// 			data: {
// 				retailerdetails: {
// 					...newAddedDetails,
// 				},
// 			},
// 		},
// 	};
// };

export const editDeliveryCharge = (params) => {
  let range;
  let { deliveryCharge } = params.details;
  deliveryCharge[params.index].charge = params.value;
  let newCharges = [...deliveryCharge];
  let distance = params.details.deliveryMaxDistance;

  let newDeliveryCharge = newCharges.map((option) => {
    if (`${option.kmRange}` === "0-3km") range = 3;
    else if (`${option.kmRange}` === "3-6km") range = 6;
    else if (`${option.kmRange}` === "6-9km") range = 9;
    else if (`${option.kmRange}` === "9-12km") range = 12;
    else if (`${option.kmRange}` === "12-15km") range = 15;
    return range <= Number(distance)
      ? {
          kmRange: option.kmRange,
          charge: option.charge,
        }
      : {
          kmRange: option.kmRange,
          charge: null,
        };
  });
  return {
    type: "NEW_EDIT_CHARGES",
    payload: {
      ...params.details,
      deliveryCharge: params.details.deliveryCharge,
    },
  };
};
export const subscriptionEdit = (params1, params2) => {
  return {
    type: "SUBSCRIPTION_EDIT",
    payload: {
      ...params2.store,
      subscription: { ...params2.store.subscription, type: params1 },
    },
  };
};

export const subscriptionDate = (params1, params2) => {
  return {
    type: "SUBSCRIPTION_DATE",
    payload: {
      ...params2.store,
      subscription: { ...params2.store.subscription, startdate: params1 },
    },
  };
};

export const subscriptionAmount = (params1, params2) => {
  return {
    type: "SUBSCRIPTION_AMOUNT",
    payload: {
      ...params2.store,
      subscription: { ...params2.store.subscription, amount: Number(params1) },
    },
  };
};

export const resetDetails = (params) => {
  return {
    type: "RESET_DETAILS",
    payload: {
      data: {
        ...params.data,
      },
    },
  };
};

export const editRetailer = (params, params1, params2) => {
  let id = params;
  return (dispatch) => {
    dispatch({
      type: "EDIT_RETAILER_WAITING",
    });
    client
      .mutate({
        mutation: UPDATE_RETAILER,
        variables: {
          retailerid: id,
          firstName: params1.firstName,
          lastName: params1.lastName,
          mobileNumber: params1.mobileNumber,
          secondaryNumber: params1.secondaryNumber,
          emailAddress: params1.emailAddress.toLowerCase(),
          PANnumber: params1.PANnumber,
          GSTnumber: params1.GSTnumber,
          aadharNumber: params1.aadharNumber,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "EDIT_RETAILER_SUCCESS",
            payload: { response: res.data, details: params2 },
          });
        } else {
          dispatch({
            type: "EDIT_RETAILER_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_RETAILER_FAILED",
          payload: err.message,
        });
      });
  };
};

export const closeSnackBars = () => {
  return {
    type: "CLOSE_SNACKBARS",
  };
};

export const getDashboardData = () => {
  return async (dispatch) => {
    dispatch({ type: "DASHBOARD_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "DASHBOARD_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "DASHBOARD_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "DASHBOARD_ERROR" });
    }
    const success = await client.query({
      query: DASHBOARD,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getNewProducts = () => {
  return async (dispatch) => {
    dispatch({ type: "NEWPRODUCTS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "NEWPRODUCTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "NEWPRODUCTS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "NEWPRODUCTS_ERROR" });
    }
    const success = await client.query({
      query: NEW_PRODUCTS,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getNewProductDetails = (params) => {
  return async (dispatch) => {
    dispatch({ type: "NEWPRODUCTDETAILS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "NEWPRODUCTDETAILS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "NEWPRODUCTDETAILS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "NEWPRODUCTDETAILS_ERROR" });
    }
    const success = await client.query({
      query: NEW_PRODUCT_DETAILS,
      variables: {
        productid: `${params}`,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const NewProductEditChanges = (params1, params2) => {
  return {
    type: "NEWPRODUCT_EDIT_CHANGE",
    payload: {
      ...params2,
      [params1.target.name]: params1.target.value,
    },
  };
};

export const getDepartmentList = () => {
  return async (dispatch) => {
    dispatch({ type: "DEPARTMENTS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "DEPARTMENTS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "DEPARTMENTS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "DEPARTMENTS_ERROR" });
    }
    const success = await client.query({
      query: DEPARTMENTS_LIST,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getCategoryList = (params) => {
  console.log(params);
  return async (dispatch) => {
    dispatch({ type: "CATEGORIES_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "CATEGORIES_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "CATEGORIES_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "CATEGORIES_ERROR" });
    }
    const success = await client.query({
      query: CATEGORY_LIST,
      variables: {
        id: `${params}`,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getCategory1List = (params) => {
  return async (dispatch) => {
    dispatch({ type: "CATEGORIES1_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "CATEGORIES1_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "CATEGORIES1_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "CATEGORIES1_ERROR" });
    }
    const success = await client.query({
      query: CATEGORY_LIST,
      variables: {
        id: `${params}`,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getCategory2List = (params) => {
  return async (dispatch) => {
    dispatch({ type: "CATEGORIES2_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "CATEGORIES2_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "CATEGORIES2_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "CATEGORIES2_ERROR" });
    }
    const success = await client.query({
      query: CATEGORY_LIST,
      variables: {
        id: `${params}`,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const addNewProduct = (params) => {
  return (dispatch) => {
    dispatch({
      type: "ADDING_NEW_PRODUCT",
    });
    client
      .mutate({
        mutation: ADD_NEW_PRODUCT,
        variables: {
          productId: params.id,
          productname: params.productname,
          desc: params.desc,
          mfgname: params.mfgname,
          brand: params.brand,
          price: params.price,
          promoprice: params.promoprice,
          quantity: `${params.quantity}`,
          uom: params.uom,
          image: {
            primary: params.image.primary,
            thumbnail: params.image.thumbnail,
            secondary: params.image.secondary,
          },
          status: params.status,
          displayrank: params.displayrank,
          approved: true,
          masterdepartment: params.masterdepartment,
          mastercategory: params.mastercategory,
          mastercategory1: params.mastercategory1,
          mastercategory2: params.mastercategory2,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "ADD_NEW_PRODUCT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ADD_NEW_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_NEW_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const deleteNewProduct = (params) => {
  return (dispatch) => {
    dispatch({
      type: "DELETING_NEW_PRODUCT",
    });
    client
      .mutate({
        mutation: DELETE_NEW_PRODUCT,
        variables: { productid: params },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "DELETE_NEW_PRODUCT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "DELETE_NEW_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_NEW_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getDepartments = () => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "DEPARTMENT_LIST_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "DEPARTMENT_LIST_FAILED", payload: error });
      return error;
    }
    // function onNetworkError() {
    //     dispatch({ type: "SELLER_VIEW_ERROR" });
    // }
    const success = await client.query({
      query: Master_Departments,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    }
    //  else return onNetworkError();
  };
};
export const AddProduct = (
  params,
  masterdepartment,
  mastercategory,
  mastercategory1,
  mastercategory2,
  multipleImg,
  thumbnail
) => {
  let secondaryImages = [];
  if (multipleImg !== null) {
    secondaryImages = multipleImg.map((m) => m.Location);
  } else {
    secondaryImages = null;
  }

  return (dispatch) => {
    dispatch({
      type: "ADDING_PRODUCTS",
    });
    client
      .mutate({
        mutation: ADD_PRODUCT,
        variables: {
          productname: params.Productname,
          desc: params.Description,
          mfgname: params.MfgName === "" ? null : params.MfgName,
          brand: params.brand === "" ? null : params.brand,
          price: params.Price,
          barcode: params.barcode,
          promoprice: params.PromoPrice === "" ? null : `${params.PromoPrice}`,
          quantity: params.Quantity === "" ? null : `${params.Quantity}`,
          uom: params.unit === "" ? null : params.unit,
          image: {
            primary:
              localStorage.getItem("imageUrl") === null ||
              localStorage.getItem("imageUrl") === "undefined" ||
              localStorage.getItem("imageUrl") === "null"
                ? null
                : localStorage.getItem("imageUrl"),
            secondary: secondaryImages,
            thumbnail:
              thumbnail !== null ? thumbnail.imageEditAndUpload.Location : null,
          },
          status: params.status,
          displayrank: Number(params.displayRank),
          masterdepartment: masterdepartment === "" ? null : masterdepartment,
          mastercategory: mastercategory === "" ? null : mastercategory,
          mastercategory1: mastercategory1 === "" ? null : mastercategory1,
          mastercategory2: mastercategory2 === "" ? null : mastercategory2,
        },
      })
      .then((res) => {
        if (res.data.createMasterProduct !== null) {
          localStorage.removeItem("imageUrl");
          localStorage.removeItem("MultipleImg", res.data);
          dispatch({
            type: "ADD_PRODUCT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ADD_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const AddPrdt = (
  params,
  masterdepartment,
  mastercategory,
  mastercategory1,
  mastercategory2
) => {
  return (dispatch) => {
    dispatch({
      type: "ADDING_PRODUCTS",
    });
    client
      .mutate({
        mutation: ADD_PRDT,
        variables: {
          productname: params.Productname,
          desc: params.Description,
          mfgname: params.MfgName === "" ? null : params.MfgName,
          brand: params.brand === "" ? null : params.brand,
          price: params.Price,
          barcode: params.barcode,
          promoprice: params.PromoPrice === "" ? null : `${params.PromoPrice}`,
          quantity: params.Quantity === "" ? null : `${params.Quantity}`,
          uom: params.unit === "" ? null : params.unit,
          status: params.status,
          displayrank: Number(params.displayRank),
          masterdepartment: masterdepartment === "" ? null : masterdepartment,
          mastercategory: mastercategory === "" ? null : mastercategory,
          mastercategory1: mastercategory1 === "" ? null : mastercategory1,
          mastercategory2: mastercategory2 === "" ? null : mastercategory2,
        },
      })
      .then((res) => {
        if (res.data.createMasterProduct !== null) {
          dispatch({
            type: "ADD_PRODUCT_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "ADD_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const setCriteria = (params) => {
  return {
    type: "SET_CRITERIA",
    payload: params,
  };
};

export const getProductSearch = (params) => {
  return async (dispatch) => {
    if (params.from === 0) {
      dispatch({ type: "SEARCH_PRODUCT_LOADING" });
    }
    function onSuccess(success) {
      dispatch({ type: "SEARCH_PRODUCT_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "SEARCH_PRODUCT_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "SEARCH_PRODUCT_ERROR" });
    }

    const success = await client.query({
      query: GET_PRODUCT_SEARCH,
      variables: {
        from: params.from,
        size: 20,
        departmentid: params.department,
        categoryid: params.category,
        categoryid1: params.category1,
        categoryid2: params.category2,
        status: params.status,
        searchKey: params.key,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const ProductSearch = (params) => {
  return async (dispatch) => {
    if (params.from === 0) {
      dispatch({ type: "PRODUCT_SEARCH_LOADING" });
    }
    function onSuccess(success) {
      dispatch({ type: "PRODUCT_SEARCH_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "PRODUCT_SEARCH_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "PRODUCT_SEARCH_ERROR" });
    }

    const success = await client.query({
      query: PRODUCT_SEARCH,
      variables: {
        pagination: {
          offset: params.from,
          first: params.first,
        },
        masterdepartment: params.department,
        mastercategory: params.category,
        mastercategory1: params.category1,
        mastercategory2: params.category2,
        status: params.status,
        searchKey: params.key,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getProductDetails = (params) => {
  return async (dispatch) => {
    function onSuccess(success) {
      dispatch({ type: "PRODUCT_DETAILS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "PRODUCT_DETAILS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "PRODUCT_DETAILS_ERROR" });
    }
    const success = await client.query({
      query: GET_PRODUCT_DETAILS,
      variables: {
        productid: params,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const setProducts = (params) => {
  let newProducts = { params };

  return {
    type: "Product_Search",
    payload: {
      newProducts,
    },
  };
};

export const UpdateProduct = (
  params,
  department,
  category,
  category1,
  category2,
  productid,
  thumbnailUrl,
  multi,
  primary
) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATING_PRODUCTS",
    });
    client
      .mutate({
        mutation: UPDATE_PRODUCT_IMG,
        variables: {
          productid: `${productid}`,
          productname: `${params.productname}`,
          desc: `${params.desc}`,
          mfgname:
            params.mfgname === "" || params.mfgname === "undefined"
              ? null
              : `${params.mfgname}`,
          brand:
            params.brand === "" || params.brand === "undefined"
              ? null
              : `${params.brand}`,
          price: `${params.price}`,
          barcode: `${params.barcode}`,
          promoprice: params.promoprice === "" ? " " : `${params.promoprice}`,
          quantity:
            params.quantity === "" || params.quantity === "undefined"
              ? null
              : `${params.quantity}`,
          uom:
            params.uom === "" || params.uom === "undefined"
              ? null
              : `${params.uom}`,
          image: {
            primary: primary,
            thumbnail: thumbnailUrl ? thumbnailUrl : null,
            secondary: multi.length === 0 ? null : multi,
          },
          status: `${params.status}`,
          displayrank: Number(params.displayrank),
          masterdepartment: department,
          mastercategory: category !== null ? category : null,
          mastercategory1: category1 !== null ? category1 : null,
          mastercategory2: category2 !== null ? category2 : null,
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "UPDATE_PRODUCT_SUCCESS",
          });
        } else {
          dispatch({
            type: "UPDATE_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const ProductUpload = (
  params,
  department,
  category,
  category1,
  category2,
  productid
) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATING_PRODUCTS",
    });
    client
      .mutate({
        mutation: UPDATE_PRODUCT,
        variables: {
          productId: `${productid}`,
          productname: `${params.productname}`,
          desc: `${params.desc}`,
          mfgname:
            params.mfgname === "" || params.mfgname === "undefined"
              ? null
              : `${params.mfgname}`,
          brand:
            params.brand === "" || params.brand === "undefined"
              ? null
              : `${params.brand}`,
          price: `${params.price}`,
          barcode: params.barcode === "" ? " " : `${params.barcode}`,
          promoprice: params.promoprice === "" ? " " : `${params.promoprice}`,
          quantity:
            params.quantity === "" || params.quantity === "undefined"
              ? null
              : `${params.quantity}`,
          uom:
            params.uom === "" || params.uom === "undefined"
              ? null
              : `${params.uom}`,
          status: `${params.status}`,
          displayrank: Number(params.displayrank),
          masterdepartment: department,
          mastercategory: category !== null ? category : null,
          mastercategory1: category1 !== null ? category1 : null,
          mastercategory2: category2 !== null ? category2 : null,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_PRODUCT_SUCCESS",
          });
        } else {
          dispatch({
            type: "UPDATE_PRODUCT_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};

export const CreatePercentage = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CREATING_PERCENTAGE_COUPON",
    });
    client
      .mutate({
        mutation: createPercentageCoupon,
        variables: {
          type: 1,
          couponCode: params.couponCode,
          custUseCount: Number(params.usesperCustomer),
          minPurAmt: Number(params.minOrder),
          startDate: new Date(params.fromtime),
          endDate: new Date(params.totime),
          isUserDependent: params.isUser,
          disAmt: Number(params.disAmt),
          discountPercent: Number(params.disPercent),
          businessTypeId: Number(params.BuisnessType),
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "PERCENTAGECOUPON_CREATED",
          });
        } else {
          dispatch({
            type: "PERCENTAGECOUPON_CREATION_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "PERCENTAGECOUPON_CREATION_FAILED",
          payload: err.message,
        });
      });
  };
};

export const CreateFlat = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CREATING_FLAT_COUPON",
    });
    client
      .mutate({
        mutation: createFlatCoupon,
        variables: {
          type: 2,
          couponCode: params.couponCode,
          custUseCount: Number(params.usesperCustomer),
          minPurAmt: Number(params.minOrder),
          disAmt: Number(params.disAmt),
          startDate: params.StartDate,
          endDate: params.ToDate,
          isUserDependent: params.isUser,
          businessTypeId: Number(params.BuisnessType),
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "FLATCOUPON_CREATED",
          });
        } else {
          dispatch({
            type: "FLATCOUPON_CREATION_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "FLATCOUPON_CREATION_FAILED",
          payload: err.message,
        });
      });
  };
};

export const CreateDeliveryFree = (params) => {
  return (dispatch) => {
    dispatch({
      type: "CREATING_DELIVERYFREE_COUPON",
    });
    client
      .mutate({
        mutation: createDeliveryFreeCoupon,
        variables: {
          type: 4,
          couponCode: params.couponCode,
          minPurAmt: Number(params.minOrder),
          custUseCount: Number(params.usesperCustomer),
          startDate: params.StartDate,
          endDate: params.ToDate,
          isUserDependent: params.isUser,
          businessTypeId: params.BuisnessType,
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "DELIVERYFREECOUPON_CREATED",
          });
        } else {
          dispatch({
            type: "DELIVERYFREECOUPON_CREATION_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELIVERYFREECOUPON_CREATION_FAILED",
          payload: err.message,
        });
      });
  };
};

export const getUserSpecificCoupons = () => {
  return async (dispatch) => {
    dispatch({ type: "LISTUSERCOUPONS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "LISTUSERCOUPONS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "LISTUSERCOUPONS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "LISTUSERCOUPONS_ERROR" });
    }
    const success = await client.query({
      query: LISTUSERCOUPONS,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const getAllCoupons = () => {
  return async (dispatch) => {
    dispatch({ type: "LISTALLCOUPONS_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "LISTALLCOUPONS_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "LISTALLCOUPONS_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "LISTALLCOUPONS_ERROR" });
    }
    const success = await client.query({
      query: LISTALLCOUPONS,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};

export const AssignCouponTouser = (params) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNING_COUPON",
    });
    client
      .mutate({
        mutation: ASSIGN_COUPON,
        variables: {
          couponId: params.CList,
          userId: params.userId,
          userCouponCount: Number(params.uses),
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "ASSIGNCOUPON_SUCCESS",
          });
        } else {
          dispatch({
            type: "ASSIGNCOUPON_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ASSIGNCOUPON_FAILED",
          payload: err.message,
        });
      });
  };
};

export const EditCoupon = (params) => {
  return (dispatch) => {
    dispatch({
      type: "EDITING_COUPON",
    });
    client
      .mutate({
        mutation: EDIT_COUPON,
        variables: {
          couponId: params.id,
          status: params.status,
        },
      })
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("updateUrl");
          dispatch({
            type: "EDIT_COUPON_SUCCESS",
          });
        } else {
          dispatch({
            type: "EDIT_COUPON_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_COUPON_FAILED",
          payload: err.message,
        });
      });
  };
};

export const Editproductstatus = (productid, params) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATING_STATUS",
    });
    client
      .mutate({
        mutation: UPDATE_PRODUCT_STATUS,
        variables: {
          productId: `${productid}`,
          status: params === true ? "active" : "inactive",
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "UPDATE_STATUS_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "UPDATE_STATUS_FAILED",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_PRODUCT_FAILED",
          payload: err.message,
        });
      });
  };
};
export const MultipleImage = () => {
  return {
    type: "MULTIPLE_FILELIST",
  };
};
export const MultipleImageReset = () => {
  return {
    type: "MULTIPLE_FILELIST_RESET",
  };
};
export const secondaryImage = (params) => {
  let Imgdata = [];
  let fileName = [];
  Imgdata = params?.files?.map((m) => m.originFileObj);
  fileName = params?.files?.map(
    (m) =>
      `${m.originFileObj.name}${Math.floor(
        Math.random() * (999 - 100 + 1) + 100
      )}.jpg`
  );
  return (dispatch) => {
    dispatch({
      type: "LOADING_MULTIPLE_UPLOAD",
    });
    client
      .mutate({
        mutation: Multiple_Upload,
        variables: {
          files: Imgdata,
          type: 2,
          storeid: `${localStorage.getItem("storeid")}`,
          fileName: fileName,
        },
      })
      .then((res) => {
        if (res.data) {
          // localStorage.setItem("MultipleImg",res.data.uploadMultipleFiles)
          dispatch({
            type: "MULTIPLE_UPLOAD_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "MULTIPLE_UPLOAD_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "MULTIPLE_UPLOAD_FAILURE",
          payload: err.message,
        });
      });
  };
};
export const getBusinessType = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_BUSINESSTYPES_LOADING" });
    function onSuccess(success) {
      dispatch({ type: "GET_BUSINESSTYPES_SUCCESS", payload: success });
      return success;
    }
    function onError(error) {
      dispatch({ type: "GET_BUSINESSTYPES_FAILED", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "GET_BUSINESSTYPES_ERROR" });
    }
    const success = await client.query({
      query: GET_BUSINESSTYPES,
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
export const ShareLink = (params) => {
  return (dispatch) => {
    dispatch({
      type: "GETTING_LINK",
    });
    client
      .mutate({
        mutation: GETLINK,
        variables: {
          storeid: params.id,
          openStore: params.openStore,
        },
      })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: "GET_LINK_SUCCESS",
            payload: res.data,
          });
        } else {
          dispatch({
            type: "GET_LINK_FAILURE",
            payload: res.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_LINK_NFAILURE",
          payload: err.message,
        });
      });
  };
};
export const setOrderDetails = (params1, params2) => {
  console.log("this is params1", params1);
  console.log("this is params2", params2);
  return {
    type: "SET_ORDER_DETAILS",
    payload: {
      customerName: params2?.customerName,
      deliveryAddress: params2?.deliveryAddress,
      deliveryCharge: params2?.deliveryCharge,
      deliveryDate: params2?.deliveryDate,
      deliveryPartnerId: params2?.deliveryPartnerId,
      deliveryTime: params2?.deliveryTime,
      finalBillAmount: params2?.finalBillAmount,
      id: params1?.id,
      lastStatus: params2?.lastStatus,
      orderNumber: params2?.orderNumber,
      productCount: params2?.products?.length,
      products: params1?.products,
      shopAssistantId: params2?.shopAssistantId,
      storeid: params1?.storeid,
      totalPrice: params2?.totalPrice,
      totalPriceDelivery: params2?.totalPriceDelivery,
      totalPriceUser: params1?.totalPriceUser,
      userid: params1?.userid,
      stats: params2?.stats,
      couponID: params1?.couponID,
      discountPrice: params2?.discountPrice,
      type: params2?.type,
    },
  };
};

export const getShopAssistantFullDetails = (id) => {
  console.log(id);
  return async (dispatch) => {
    dispatch({ type: "SHOPASSISTANT_DETAILS_LOADING" });
    function onSuccess(success) {
      dispatch({
        type: "SHOPASSISTSNT_DETAILS",
        payload: success?.data.viewParticularShopAssistant,
      });
      return success;
    }
    function onError(error) {
      dispatch({ type: "SHOPASSISTSNT_DETAILS", payload: error });
      return error;
    }
    function onNetworkError() {
      dispatch({ type: "SHOPASSISTSNT_DETAILS_ERROR" });
    }
    const success = await client.query({
      query: getShopAssistantDetails,
      variables: {
        shopAssistantId: id,
      },
    });
    if (success) {
      return onSuccess(success);
    } else if (success.errors) {
      return onError(success.errors[0]);
    } else return onNetworkError();
  };
};
